/* eslint-disable */
import React from "react";
import { Button, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import Modal1 from "../Modal/Modal1.component";
import Modal2 from "../Modal/Modal2.component";
import Modal3 from "../Modal/Modal3.component";
import "../../../../css/Home/Calendar.css";
import ReactLoading from "react-loading";
import Modal4 from "../Modal/Modal4.component";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.Modal_One = this.Modal_One.bind(this);
    this.Modal_Two = this.Modal_Two.bind(this);
    this.onChangecheckBoxSubmit = this.onChangecheckBoxSubmit.bind(this);
    this.Modal_Three = this.Modal_Three.bind(this);
    this.onChangecheckBoxSubmit_2 = this.onChangecheckBoxSubmit_2.bind(this);
    this.Modal_Four = this.Modal_Four.bind(this);
    this.onChangecheckBoxSubmit_3 = this.onChangecheckBoxSubmit_3.bind(this);
    this.state = {
      activeDate: new Date(),
      selectDay: null,
      Holiday: [],
      siteOpen: [],
      roundOpen: [],
      isModal: false,
      isModal_2: false,
      checkBoxSubmitModal: false,
      isModal_3: false,
      checkBoxSubmitModal_2: false,
      date: null, // วันที่เลือกเข้ารับบริการ
      formatDate: null, // วันจันทร์ 16 ม.ค. ได้ทั้งไทยเเละ ENG
      round: null, // รอบเวลาที่เข้ารับบริการ
      count: null, //จำนวนยังว่าง
      Maxcount: null, // จำนวนทั้งหมดที่เปิดจอง
      loading: false,
      isModal_4: false,
      checkBoxSubmitModal_3: false,
      one_year: false,
      day_dif: 0,
      showRoundOpen: false,
    };
  }

  //set เลือกวัน เป้น NULL
  setSelectDay = () => {
    this.setState({
      selectDay: null,
    });
  };

  showRoundOpen = (status) => {
    this.setState({
      showRoundOpen: status,
    });
  };

  // Modal โชว์ตอนกดเลือกเวลา
  Modal_One(type) {
    this.setState({
      isModal: type,
    });
  }
  // Modal ทำการภายใน 180 วัน ก่อนถึงวันสิ้นอายุบัตร
  Modal_Two(type) {
    this.setState({
      isModal_2: type,
    });
  }
  // checkbox ของ Modal ทำการภายใน 180 วัน ก่อนถึงวันสิ้นอายุบัตร
  onChangecheckBoxSubmit(e) {
    this.setState({
      checkBoxSubmitModal: e.target.checked,
    });
  }

  // Modal หมดอายุเกิน1ปี
  Modal_Three(type) {
    this.setState({
      isModal_3: type,
    });
  }

  // checkbox ของ Modal หมดอายุเกิน1ปี
  onChangecheckBoxSubmit_2(e) {
    this.setState({
      checkBoxSubmitModal_2: e.target.checked,
    });
  }

  // Modal เงือ่นไขของรถ 2 ชนิด
  Modal_Four(type) {
    this.setState({
      isModal_4: type,
    });
  }

  onChangecheckBoxSubmit_3(e) {
    this.setState({
      checkBoxSubmitModal_3: e.target.checked,
    });
  }

  async componentDidMount() {
    try {
      let time = new Date();
      const formatYmd = time.toISOString().slice(0, 10);
      const Holiday = await axios.get(
        process.env.REACT_APP_API_DLT3 + "/holiday",
        {
          mode: "cors",
          params: {
            tyw_id: this.props.data.Typework.tyw_id,
          },
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const HolidayJson = await Holiday.data;
      let data = {
        tyw_id: this.props.data.Typework.tyw_id,
        currentDate: formatYmd,
      };
      const siteroundopen = await axios.get(
        process.env.REACT_APP_API_DLT3 + "/siteroundopen",
        {
          params: data,
        }
      );
      const siteroundopeneJson = await siteroundopen.data;
      this.setState({
        Holiday: HolidayJson,
        siteOpen: siteroundopeneJson,
        loading: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  weekDays = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];
  weekDaysEN = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  monthsEN = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  generateMatrix() {
    var matrix = [];
    matrix[0] = this.props.language === 1 ? this.weekDays : this.weekDaysEN;
    // header calendars
    var year = this.state.activeDate.getFullYear();
    var month = this.state.activeDate.getMonth();
    var firstDay = new Date(year, month, 1).getDay();
    var maxDays = this.nDays[month];

    // Check if February has 28 or 29 days.
    if (month == 1) {
      if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
        maxDays += 1;
      }
    }

    var counter = 1;
    // generate days in month
    for (var row = 1; row < 7; row++) {
      matrix[row] = [];
      for (var col = 0; col < 7; col++) {
        matrix[row][col] = -1;
        //firstDay-1 swap index Sunday to index 6
        if (row == 1 && col >= firstDay) {
          matrix[row][col] = counter++;
        } else if (row > 1 && counter <= maxDays) {
          matrix[row][col] = counter++;
        }
      }
    }
    return matrix;
  }

  disableDay(item) {
    let yyyy = this.state.activeDate.getFullYear().toString();
    let mm = (this.state.activeDate.getMonth() + 1).toString();
    let day = (d) => {
      let dd;
      if (d > 9) {
        dd = d.toString();
      } else {
        dd = "0" + d.toString();
      }
      return dd;
    };
    let month = (m) => {
      let mm;
      if (["10", "11", "12"].includes(m)) {
        mm = m;
      } else {
        mm = "0" + m;
      }
      return mm;
    };
    let press_Date = yyyy + "-" + month(mm) + "-" + day(item);
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      month(today.getMonth() + 1) +
      "-" +
      day(today.getDate());
    let CurrentDay = new Date(date);
    let OnClickDay = new Date(press_Date);
    let Difference_In_Time = CurrentDay.getTime() - OnClickDay.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let disableDay;
    if (Difference_In_Days < 0 && Difference_In_Days >= -180) {
      disableDay = true;
    } else {
      disableDay = false;
    }
    return disableDay;
  }

  checkShowModal(data, item) {
    let isModal;
    if (data === null) {
      isModal = 3; // ไม่โชว์ Modal
    } else {
      let yyyy = this.state.activeDate.getFullYear().toString();
      let mm = (this.state.activeDate.getMonth() + 1).toString();
      let day = (d) => {
        let dd;
        if (d > 9) {
          dd = d.toString();
        } else {
          dd = "0" + d.toString();
        }
        return dd;
      };
      let month = (m) => {
        let mm;
        if (["10", "11", "12"].includes(m)) {
          mm = m;
        } else {
          mm = "0" + m;
        }
        return mm;
      };
      let press_Date = yyyy + "-" + month(mm) + "-" + day(item);
      let DayFromNow_1 = new Date(data);
      let DayFromNow_2 = new Date(press_Date);
      let Difference_In_Time = DayFromNow_1.getTime() - DayFromNow_2.getTime();
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 180) {
        isModal = 1; // โชว์ Modal 2
      } else if (Difference_In_Days <= -365) {
        this.state.day_dif = Math.abs(Difference_In_Days);
        isModal = 2; // โชว์ Modal 2
      }
    }
    return isModal;
  }

  InfoCar(data, item) {
    if (data.length < 2) {
      if (data[0].date === null) {
        return null;
      } else {
        let isShowModal = this.checkShowModal(data[0].date, item);
        return isShowModal;
      }
    } else {
      let checked_1 = this.checkShowModal(data[0].date, item);
      let checked_2 = this.checkShowModal(data[1].date, item);
      // 1 คือ จองล่วงหน้าใน 180 วัน
      // 2 คือ หมดอายุเกิน 1 ปี
      // 3 คือ สามารถจองได้ปกติ
      // 4 คือ ไม่สามารถจองได้เนื่องจากบัตรทั้ง 2 ไม่เข้าเงื่อนไขในการให้บริการ
      if ([1].includes(checked_1) || [1].includes(checked_2)) {
        return 4;
      } else {
        return 3;
      }
    }
  }

  _onPress(item, colIndex, data, holidays) {
    let disableDay = this.disableDay(item);
    this.setState(() => {
      if (
        // [0, 6].includes(colIndex) ||
        disableDay === false ||
        ["#FF0000", "#000"].includes(data.color) ||
        holidays === true
      ) {
        this.state.roundOpen = null;
        this.state.selectDay = null;
        this.state.showRoundOpen = false;
        return this.state;
      } else if (!item.match && item != -1) {
        let checkShowModal = this.InfoCar(this.props.data.Info, item);
        if (checkShowModal === 1) {
          // item = null;
          this.state.selectDay = null;
          data.siteopen = null;
          this.state.isModal_2 = true;
          this.state.showRoundOpen = false;
          // show modal 1 ปี หรือ 3 ปี
        } else if (checkShowModal === 2) {
          this.state.selectDay = item;
          this.setState({
            one_year: true,
          });
          this.state.isModal_3 = true;
        } else if (checkShowModal === 4) {
          // item = null;
          this.state.selectDay = null;
          data.siteopen = null;
          this.state.isModal_4 = true;
          this.state.showRoundOpen = false;
        } else if (
          checkShowModal === null ||
          typeof checkShowModal === "undefined" ||
          checkShowModal === 3
        ) {
          this.state.selectDay = item;
          this.state.showRoundOpen = true;
        }
        // this.state.activeDate.setDate(item);
        let YMD = this.formatDate(item);
        this.state.date = YMD;
        this.state.formatDate = this.formatYmdTH(YMD);
        this.state.roundOpen = data.siteopen;
        return this.state;
      }
    });
  }

  formatDate(item) {
    let Month = [9, 10, 11].includes(this.state.activeDate.getMonth())
      ? this.state.activeDate.getMonth() + 1
      : "0" + (this.state.activeDate.getMonth() + 1);
    let day = item > 9 ? item : "0" + item;
    let YMD = this.state.activeDate.getFullYear() + "-" + Month + "-" + day;
    return YMD;
  }

  formatYmdTH(YMD) {
    const split = YMD.split("-");
    let d = new Date(YMD);
    let monthNamesThai = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    let dayNamesThai = [
      "วัน อาทิตย์ ที่",
      "วัน จันทร์ ที่",
      "วัน อังคาร ที่",
      "วัน พุธ ที่",
      "วัน พฤหัสบดี ที่",
      "วัน ศุกร์ ที่",
      "วัน เสาร์ ที่",
    ];
    let MonthsEN = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let result;
    if (this.props.language === 1) {
      result =
        "" +
        dayNamesThai[d.getDay()] +
        " " +
        split[2] +
        " " +
        monthNamesThai[parseInt(split[1]) - 1] +
        " " +
        (parseInt(split[0]) + 543);
    } else {
      result =
        " " +
        split[2] +
        " " +
        MonthsEN[parseInt(split[1]) - 1] +
        " " +
        parseInt(split[0]);
    }
    return result;
  }

  changeMonth = (n) => {
    this.state.activeDate.setMonth(this.state.activeDate.getMonth() + n);
    this.setState(() => {
      this.state.selectDay = null;
      this.state.roundOpen = null;
      this.state.showRoundOpen = false;
      return this.state;
    });
  };

  holiday() {
    var holiday = [];
    let year = this.state.activeDate.getFullYear();
    let month = this.state.activeDate.getMonth();
    // monthly holiday
    for (let index = 0; index < this.state.Holiday.length; index++) {
      let splitDay = this.state.Holiday[index].hol_date.split("-");
      if (
        parseInt(splitDay[1]) === month + 1 &&
        parseInt(splitDay[0]) === year
      ) {
        holiday.push(parseInt(splitDay[2]));
      }
    }
    return holiday;
  }

  siteopen() {
    var open = [];
    let year = this.state.activeDate.getFullYear();
    let month = this.state.activeDate.getMonth();
    for (let index = 0; index < this.state.siteOpen.length; index++) {
      let splitDay = this.state.siteOpen[index].date.split("-");
      if (
        parseInt(splitDay[1]) === month + 1 &&
        parseInt(splitDay[0]) === year
      ) {
        open.push({
          Day: parseInt(splitDay[2]),
          color: this.state.siteOpen[index].color,
          siteopen: this.state.siteOpen[index].siteopen,
        });
      }
    }
    return open;
  }

  data(siteopen, item) {
    let data = {};
    let check = false;
    siteopen.forEach((element) => {
      if (element.Day === item) {
        data = {
          color: element.color,
          siteopen: element.siteopen,
        };
        check = true;
      }
    });
    if (check === false) {
      data = {
        color: "#000",
        siteopen: "ไม่เปิดรับบริการ",
      };
    }
    return data;
  }

  convertDateTime = (time, time_unit) => {
    let time_thai = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    let txt_time = "";
    if (time >= 12) {
      const isLargeNumber = (element) => element == time;
      txt_time = time_thai.findIndex(isLargeNumber) + ":" + time_unit + " PM";
    } else {
      txt_time = time + ":" + time_unit + " AM";
    }
    return txt_time;
  };

  checkDayoff(colIndex, item, data) {
    if ([0, 6].includes(colIndex) && item != data.Day) {
    }
  }

  render() {
    let matrix = this.generateMatrix();
    let rows = [];
    let count = 0;
    let holidays = this.holiday();
    let siteopen = this.siteopen();
    rows = matrix.map((row, rowIndex) => {
      var rowItems = row.map((item, colIndex) => {
        count++;
        let data = this.data(siteopen, item);
        return (
          <div key={count.toString()} className="day">
            <div
              className={
                this.state.selectDay === item
                  ? "position_day text_day"
                  : "position_day hover_day"
              }
            >
              <span
                style={{
                  color: holidays.includes(item) ? "#C2C2C2" : data.color,
                }}
                onClick={() =>
                  this._onPress(item, colIndex, data, holidays.includes(item))
                }
              >
                {item != -1 && data.color != "#FF0000" ? item : ""}
              </span>
              {data.color === "#FF0000" ? (
                holidays.includes(item) ? (
                  <span style={{ color: "#C2C2C2" }}>
                    {item != -1 ? item : ""}
                  </span>
                ) : (
                  <span style={{ color: "#FF0000" }}>
                    {this.props.dict[13]}
                  </span>
                )
              ) : null}
            </div>
          </div>
        );
      });
      return (
        <div key={rowIndex} className="rows">
          {rowItems}
        </div>
      );
    });
    return (
      <div>
        <h2 className="title">{this.props.dict[1]}</h2>
        <div className="container_calendar">
          <div className="Calendar">
            <div className="Header">
              {this.state.selectDay === null ? (
                <span>
                  {this.props.dict[10]} : {this.props.dict[11]}
                </span>
              ) : (
                <span>
                  {this.props.dict[10]} {this.state.selectDay} :{" "}
                  {this.props.dict[11]}
                </span>
              )}
            </div>
            <hr className="rounded" />
            <div className="bg_calendar">
              <div className="HeaderCalendar">
                {this.props.language === 1 ? (
                  <span className="date">
                    {this.months[this.state.activeDate.getMonth()]} &nbsp;
                    {this.state.activeDate.getFullYear() + 543}
                  </span>
                ) : (
                  <span className="date">
                    {this.monthsEN[this.state.activeDate.getMonth()]} &nbsp;
                    {this.state.activeDate.getFullYear()}
                  </span>
                )}
                <div className="flex-row">
                  <LeftOutlined
                    style={{ color: "#2f0465", marginRight: "2%" }}
                    onClick={() => this.changeMonth(-1)}
                    className="icon"
                  />
                  <RightOutlined
                    style={{ color: "#2f0465" }}
                    onClick={() => this.changeMonth(1)}
                    className="icon"
                  />
                </div>
              </div>
              {this.state.loading === true ? (
                <div className="table_calendar">{rows}</div>
              ) : (
                <div className="size-loadding">
                  <ReactLoading type="spinningBubbles" color={"#2f0465"} />
                </div>
              )}
              {/* <div className="table_calendar">{rows}</div> */}
            </div>
            <hr className="rounded" />
            <div className="advice">
              <div className="column">
                <div className="one">
                  <span className="dot1"></span>
                  <span className="color_txt1">{this.props.dict[3]}</span>
                </div>
                <div className="one">
                  <span className="dot2"></span>
                  <span className="color_txt2">{this.props.dict[5]}</span>
                </div>
              </div>
              <div className="column">
                <div className="one">
                  <span className="dot4"></span>
                  <span className="color_txt4">{this.props.dict[4]}</span>
                </div>
                <div className="one">
                  <span className="dot3"></span>
                  <span className="color_txt3">{this.props.dict[6]}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="RoundOpen">
            <div className="Headder_round">
              <div className="header_office">
                <span>{this.props.dict[8]}</span>
                <p>{this.props.data.site.site_name}</p>
              </div>
              <div className="header_office">
                <span>{this.props.dict[9]}</span>
                <p>{this.props.data.Typework.tyw_name}</p>
              </div>
            </div>
            <div className="choose_day">
              {this.state.selectDay === null ? (
                <span>{this.props.dict[14]}</span>
              ) : (
                <span>
                  {this.props.dict[7]} {this.props.dict[10]}{" "}
                  {this.state.selectDay}
                </span>
              )}
            </div>
            <div className="footer-calendar-roundItem">
              <div className="footer_roundTime">
                <div className="box-calendar-footer">
                  {/* <div className="size-box-calendar"> */}
                  {this.state.showRoundOpen === false
                    ? null
                    : this.state.roundOpen.map((item, index) => {
                        let Time =
                          this.props.language === 1
                            ? item.round.substring(0, 5) + " น."
                            : this.convertDateTime(
                                parseInt(item.round.substring(0, 2)),
                                item.round.substring(3, 5)
                              );
                        if (item.count === "เต็ม") {
                          return (
                            <div
                              key={index.toString()}
                              className="box_time bg_full pointer_full"
                            >
                              <span className="full">
                                {this.props.dict[13]}
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={index.toString()}
                              className="box_time bg_free pointer_free"
                              onClick={() => [
                                this.Modal_One(true),
                                this.setState({
                                  round: item.round,
                                  count: item.count,
                                  Maxcount: item.MaxCount,
                                }),
                              ]}
                            >
                              <span className="free">{Time}</span>
                            </div>
                          );
                        }
                      })}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <Modal1
            Modal_One={this.Modal_One}
            isModal={this.state.isModal}
            next={this.props.next}
            round={this.state.round}
            dateBook={this.state.date}
            formatdate={this.state.formatDate}
            count={this.state.count}
            Maxcount={this.state.Maxcount}
            Modaldict={this.props.Modaldict}
            one_year={this.state.one_year}
            language={this.props.language}
            day_dif={this.state.day_dif}
          />
          <Modal2
            Modal_Two={this.Modal_Two}
            isModal={this.state.isModal_2}
            onChangecheckBoxSubmit={this.onChangecheckBoxSubmit}
            checkBoxSubmitModal={this.state.checkBoxSubmitModal}
            ModaldictWarning={this.props.Modaldict_2}
          />
          <Modal3
            Modal_Three={this.Modal_Three}
            isModal={this.state.isModal_3}
            onChangecheckBoxSubmit={this.onChangecheckBoxSubmit_2}
            checkBoxSubmitModal_2={this.state.checkBoxSubmitModal_2}
            ModaldictWarning={this.props.Modaldict_2}
            day_dif={this.state.day_dif}
            showRoundOpen={this.showRoundOpen}
            setSelectDay={this.setSelectDay}
          />
          <Modal4
            Modal_Four={this.Modal_Four}
            isModal={this.state.isModal_4}
            onChangecheckBoxSubmit={this.onChangecheckBoxSubmit_3}
            checkBoxSubmitModal_3={this.state.checkBoxSubmitModal_3}
            ModaldictWarning={this.props.Modaldict_2}
            language={this.props.language}
          />
        </div>
      </div>
    );
  }
}
export default Calendar;
