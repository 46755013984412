import React from "react";
import { Modal, Button, Tag, Row, Col } from "antd";
import "./ModalQueue.css";
import Ticket from "../../Ticket/Ticket.component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";
import axios from "axios";

class DetailQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      timestamp: this.props.data.boo_datetime,
      boo_code: this.props.data.boo_code,
      // loading: false,
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  CancelQueue = async () => {
    try {
      this.setState({ loading: true });
      const data = {
        username: this.props.username,
        timestamp: this.props.data.boo_datetime,
        boo_code: this.props.data.boo_code,
        staff_username: this.props.username_Admin,
      };
      const cancle_booking = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/adWeb/cancelBooking",
        // "http://gecc.dlt.go.th:13999/dlt-api1/adWeb/cancelBooking",
        data,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const Cancle_BookingJson = await cancle_booking.data;
      if (Cancle_BookingJson === "cancel booking by adWeb complete") {
        // setTimeout(() => {
        // this.setState({ loading: false, visible: false });
        // }, 2000);
        this.setState({ visible: false });
        this.props.handleUpdate();
        toast.configure();
        toast.error("ยกเลิกการจองคิวเสร็จสิ้น", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  printTicket() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button
              className="position-Modal"
              key="back"
              // onClick={this.handleCancel}
            >
              พิมพ์อีกครั้ง
            </Button>
          )}
          content={() => this.componentRef}
        />
        <div style={{ display: "none" }}>
          <Ticket
            data={this.props.data}
            boo_code={this.props.data.boo_code}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
      </div>
    );
  }

  render() {
    // const { visible, loading } = this.state;
    return (
      <div>
        <Tag className="pointor-tag" color={"red"} onClick={this.showModal}>
          ยกเลิกการจองคิว
        </Tag>
        <Modal
          visible={this.state.visible}
          title="ยกเลิกการจองคิว"
          onCancel={this.handleCancel}
          footer={[
            this.printTicket(),
            <Button
              key="submit"
              className="close-modal-Queue"
              type="primary"
              danger
              onClick={this.handleCancel}
            >
              ปิด
            </Button>,
            <Button
              key="cacle"
              type="primary"
              // loading={this.state.loading}
              onClick={this.CancelQueue}
            >
              ยืนยันการยกเลิก
            </Button>,
          ]}
        >
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              รหัสการจอง
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.boo_code}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              วันที่จอง
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.boo_datebook}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              รอบที่จอง
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.boo_round}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              ประเภทงาน
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.tyw_name}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              สถานที่รับบริการ
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.sit_name}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
export default DetailQueue;
