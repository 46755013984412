import React from "react";
import { List } from "antd";

class LicenseType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  AddIndexJson(data) {
    for (let i = 0; i < 2; i++) {
      data.push({ kw: null, gotwork: false });
    }
    return data;
  }

  render() {
    return (
      <div>
        <p className="title">{this.props.dict[0]}</p>
        <div className="container_office">
          <List className="listItem-LicenseType" itemLayout="horizontal">
            <List.Item
              className={
                this.props.data.ValueCheckEmpty.data[3].gotwork === false
                  ? "disable-item"
                  : "item"
              }
              onClick={() =>
                this.props.data.ValueCheckEmpty.data[3].gotwork === false
                  ? null
                  : [
                      this.props.next(
                        1,
                        {
                          group_id: {
                            group_id: this.props.language === 1 ? 5 : 4,
                            group_name: this.props.dict[1],
                            typeScreen: 0,
                          },
                          EmptyFillter: {
                            data: this.props.data.ValueCheckEmpty.data[3]
                              .filter,
                          },
                        },
                        this.props.language === 1
                          ? "ประเภทบริการ"
                          : "Service type",
                        this.props.language === 1
                          ? "เลือกประเภทรับบริการ"
                          : "Select service type."
                      ),
                    ]
              }
            >
              <p className="site">{this.props.dict[1]}</p>
            </List.Item>
            {this.props.language === 1 ? (
              <List.Item
                className={
                  this.props.data.ValueCheckEmpty.data[4].gotwork === false
                    ? "disable-item"
                    : "item"
                }
                onClick={() =>
                  this.props.data.ValueCheckEmpty.data[4].gotwork === false
                    ? null
                    : [
                        this.props.next(
                          1,
                          {
                            group_id: {
                              group_id: 6,
                              group_name: "ใบอนุญาตรถสาธารณะ",
                              typeScreen: 1,
                            },
                            EmptyFillter: {
                              data: this.AddIndexJson(
                                this.props.data.ValueCheckEmpty.data[4].filter
                              ),
                            },
                          },
                          this.props.language === 1
                            ? "ประเภทบริการ"
                            : "Service type",
                          this.props.language === 1
                            ? "เลือกประเภทรับบริการ"
                            : "Select service type."
                        ),
                      ]
                }
              >
                <p className="site">{this.props.dict[2]}</p>
              </List.Item>
            ) : null}
            {this.props.language === 1 ? (
              <List.Item
                className={
                  this.props.data.ValueCheckEmpty.data[5].gotwork === false
                    ? "disable-item"
                    : "item"
                }
                onClick={() =>
                  this.props.data.ValueCheckEmpty.data[5].gotwork === false
                    ? null
                    : [
                        this.props.next(
                          1,
                          {
                            group_id: {
                              group_id: 7,
                              group_name: "ใบอนุญาต พรบ. ขนส่ง",
                              typeScreen: 2, // เเสดงประเภทงานของงานใบอนุญาติ
                            },
                            EmptyFillter: {
                              data: this.AddIndexJson(
                                this.props.data.ValueCheckEmpty.data[5].filter
                              ),
                            },
                          },
                          this.props.language === 1
                            ? "ประเภทบริการ"
                            : "Service type",
                          this.props.language === 1
                            ? "เลือกประเภทรับบริการ"
                            : "Select service type."
                        ),
                      ]
                }
              >
                <p className="site">{this.props.dict[3]}</p>
              </List.Item>
            ) : null}
          </List>
        </div>
      </div>
    );
  }
}

export default LicenseType;
