/* eslint-disable */
import React from "react";
import axios from "axios";
import { List, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ReactLoading from "react-loading";

class Typework extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      txt_search: "",
      loading: false,
    };
  }

  async componentDidMount() {
    let _username = localStorage.getItem("username");
    try {
      const data = {
        username: _username,
        sit_id: this.props.data.site.site_id,
        group_id: this.props.data.group_id.group_id,
        kw: this.props.data.Service.Keyword,
      };
      const typeWork = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/workfilter",
        data,
        {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const typeWorkJson = await typeWork.data;
      this.setState({
        data: typeWorkJson,
        loading: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <p className="title">{this.props.dict[0]}</p>
        {/* <p className="subtitle">{this.props.dict[1]}</p> */}
        <div className="container_office">
          <div className="Search">
            <Input
              className="border-shadow"
              placeholder={this.props.dict[2]}
              suffix={<SearchOutlined />}
              onChange={(e) => {
                this.setState(() => {
                  this.state.txt_search = e.target.value;
                  return this.state;
                });
              }}
            />
          </div>
        </div>
        <div className="container_office">
          {this.state.loading === true ? (
            <List className="listItem-typework" itemLayout="horizontal">
              {this.state.data
                .filter((item) => {
                  if (this.state.txt_search === "") {
                    return item;
                  } else if (
                    item.tyw_name.toLowerCase().includes(this.state.txt_search)
                  ) {
                    return item;
                  }
                })
                .map((item) => (
                  <List.Item
                    className="item"
                    key={item.tyw_id.toString()}
                    onClick={() =>
                      this.props.next(
                        1,
                        {
                          Typework: {
                            tyw_id: item.tyw_id,
                            tyw_name: item.tyw_name,
                          },
                        },
                        this.props.language === 1 ? "ปฏิทิน" : "Calendar",
                        this.props.language === 1
                          ? "เลือกวันและเวลาที่ต้องการ"
                          : "Select a date and time for service"
                      )
                    }
                  >
                    <p className="site">{item.tyw_name}</p>
                  </List.Item>
                ))}
            </List>
          ) : (
            <div className="size-loadding">
              <ReactLoading type="spinningBubbles" color={"#2f0465"} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Typework;
