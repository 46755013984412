/* eslint-disable */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../../Auth/Login/Login.screen";
import Register from "../../Auth/Register/Register.screen";
import DocumentaryForeigner from "../../Auth/Register/DocumentaryForeigner.screen";
import Information from "../../Auth/Register/Information.screen";
import ForgotPassword from "../../Auth/ForgotPassword/ForgotPassword.screen";
import InformationForgotForgot from "../../Auth/ForgotPassword/InformationForgot.screen";
import TicketMobile from "../../../Admin/Ticket/TicketMobile.component";
import ProtectedRoute from "../../../Route/ProtectedRoute";
import Navbar from "../Navbar";
import NavbarAdmin from "../../../Admin/Components/Navbar";
import ProtectedRouteAdmin from "../../../Route/ProtectedRouteAdmin";
import CryptoJS from "crypto-js";
import MainAuth from "../../Auth/Login/MainAuth.screen";
import CallbackThaID from "../../ThaID/CallbackThaID.screen";

class AuthRouter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route path="/main">
          <MainAuth />
        </Route>
        <Route path="/login">
          <Login />
        </Route>

        <Route path="/callback/thaid" component={CallbackThaID} />

        <ProtectedRoute path="/dlt-Smartqueue">
          <Navbar />
        </ProtectedRoute>
        <ProtectedRouteAdmin path="/admin">
          <NavbarAdmin />
        </ProtectedRouteAdmin>
        {/* <Route path="/register" component={Register} /> */}
        <Route path="/register" component={DocumentaryForeigner} />
        <Route path="/information" component={Information} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route
          path="/informationforgotForgotPassword"
          component={InformationForgotForgot}
        />
        <Route path="/ticket/:boocode" component={TicketMobile} />
        <Route exact path="/">
          <Redirect exact from="/" to="dlt-Smartqueue" />
        </Route>
      </Switch>
    );
  }
}

export default AuthRouter;
