import axios from "axios";
import React from "react";
import { withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";
import ReactLoading from "react-loading";
import Logo from "../../../Icon/logo-web.svg";
import { Col, Row } from "antd";

class CallbackThaID extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {}, test: "" };
  }

  checkThaid = async (code) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_DLT2}/login/thaid`,
        {
          code,
        }
      );

      // Set state with the response data
      console.log(result.data);
      this.setState({ data: result.data.accessToken });

      // Attempt to open the deep link with a delay
      const deepLink = `dltsmartqueue://open?AT=${result.data.accessToken}&RT=${result.data.refreshToken}`;

      const userAgent = navigator.userAgent || window.opera;
      const isAndroid = /android/i.test(userAgent);
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      if (isAndroid || isIOS) {
        window.location.href = deepLink;
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_URI;
          this.checkRoute(result.data);
          window.close();
        }, 3500);
      } else {
        window.opener.document.location.href = process.env.REACT_APP_URI;
        this.checkRoute(result.data);
      }
    } catch (err) {
      console.log(err);
      // window.close();
    }
  };

  checkRoute(data) {
    var roledata = data.role;
    var lang;
    if (data.lang == "EN") {
      lang = CryptoJS.AES.encrypt("2", data.username);
    } else {
      lang = CryptoJS.AES.encrypt("1", data.username);
    }
    localStorage.setItem("username", data.username);
    localStorage.setItem("lang", lang);
    let check = roledata == 0 ? "/dlt-Smartqueue" : "/admin";
    var role = CryptoJS.AES.encrypt(roledata.toString(), data.username);
    localStorage.setItem("role", role);
    this.props.history.push({
      pathname: check,
    });
    if (check === '/dlt-Smartqueue') {
      setTimeout(() => {
        window.close()
      }, 400)
    }
  }
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get("code");
    this.checkThaid(code);
  }

  render() {
    // console.log(this.props.history.location.pathname)
    return (
      <div className="content-login">
        {/* row-logo */}
        <Row className="row-Logo-login" justify="center" align="top">
          {/* xs < 576 sm >=576 xl >=1200 */}
          <Col className="col-Logo-login" xs={20} sm={8} xl={6}>
            <img className="logo-login-page" src={Logo} />
          </Col>
        </Row>
        <Row className="row-Content-login" justify="center" align="top">
          <ReactLoading type="spinningBubbles" color={"#2f0465"} />
        </Row>
      </div>
    );
  }
}
export default withRouter(CallbackThaID);
