/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Image, Divider, Modal, Button, Row, Col } from "antd";
import "./profile.css";
import axios from "axios";
import Logo from "../../img/logo/LogoDLT.png";
import Close2 from "../../img/icon/close2.svg";
import Triangle from "../../img/icon/triangle icon.svg";
import Userfill from "../../img/icon/User_fill.svg";
import Daterange from "../../img/icon/Date_range.svg";
import Phone from "../../img/icon/phone.svg";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // dict
      dict: require("../../../dictionary/menudict.json"),
      modalVisibke: true,
      // information
      tit_id: "",
      tit_name: "",
      fName: "",
      lName: "",
      birthDay: "",
      passport: "",
      phone: "",
      email: "",

      // datashow
      PhoneEmailShow: "",
      // datacheck
      has_booking: "",
      username: this.props.username,
      language: this.props.language,
    };
  }

  modalalert() {
    this.setState({ modalVisible: true });
  }

  _click() {
    this.setState({ modalVisible: false });
  }

  componentDidMount() {
    const data = { username: this.state.username };
    try {
      axios
        .post(process.env.REACT_APP_API_DLT1 + "/getPersonalProfile", data)
        .then((response) => {
          if (response.data !== "wrong username") {
            // prefix
            if (this.state.language == 1) {
              this.setState({
                tit_name: response.data.tit_nameth,
              });
            } else {
              this.setState({
                tit_name: response.data.tit_nameen,
              });
            }

            // birthday
            let showData = "";
            if (response.data.per_birthday == null) {
              if (this.state.language == 1) {
                showData = "ไม่ทราบวันเกิด";
              } else {
                showData = "birth unknown";
              }
            } else {
              const dateData = response.data.per_birthday.split("-");
              if (this.state.language == 1) {
                showData =
                  dateData[2] +
                  "-" +
                  dateData[1] +
                  "-" +
                  (parseInt(dateData[0]) + 543);
              } else {
                showData =
                  dateData[2] + "-" + dateData[1] + "-" + parseInt(dateData[0]);
              }
            }

            // phone and email show
            let dataPhoneEmail = "";
            if (response.data.per_email == "") {
              dataPhoneEmail = response.data.per_phone + " / ";
            } else {
              dataPhoneEmail =
                response.data.per_phone + " / " + response.data.per_email;
            }
            // set data to state
            this.setState({
              tit_id: response.data.per_id,
              fName: response.data.per_fname,
              lName: response.data.per_lname,
              birthDay: showData,
              phone: response.data.per_phone,
              email: response.data.per_email,
              has_booking: response.data.has_booking,
              PhoneEmailShow: dataPhoneEmail,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    // check data in localStorage
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 0
    ) {
      return <Redirect to="/main" />;
    }
    const dictProfile =
      this.state.dict[this.state.language].Menu[3].WordPackage[1].Words;

    const dictProfileModalwarning =
      this.state.dict[this.state.language].Menu[3].WordPackage[0].Words;
    console.log(this.state.language !== "1");
    return (
      <div className="container-profile">
        <Modal
          className="modalprofile"
          footer={null}
          closable={false}
          visible={this.state.modalVisible}
        >
          <div>
            <img
              className="btn-close"
              src={Close2}
              onClick={() => this._click()}
            />
          </div>
          <div className="titleAlert">
            {this.state.language == 1 ? "คำเตือน" : "Warning"}
          </div>
          <div className="textModal">{dictProfileModalwarning[1]}</div>
          <div className="divbe">
            <Button className="btn-be" onClick={() => this._click()}>
              {dictProfileModalwarning[2]}
            </Button>
          </div>
        </Modal>

        <Row justify="center" className="row-profile-main">
          <Col xs={20} sm={20} xl={20} className="bg-profile-content">
            <Row justify="center" align="top">
              <Col xs={5} sm={3} xl={2}>
                <img className="logoprofile" preview="false" src={Logo} />
              </Col>
            </Row>

            <div className="titleprofile">{dictProfile[0]}</div>

            <Row>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 12, offset: 6 }}
                xl={{ span: 12, offset: 6 }}
              >
                <Row>
                  <Divider />
                  {/* id */}
                  <Col xs={24} sm={24} xl={24}>
                    <Row>
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                      >
                        <div style={{ paddingLeft: 20 }}>
                          <Row>{dictProfile[1]}</Row>
                        </div>
                      </Col>

                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                      >
                        {this.state.tit_id}
                      </Col>
                    </Row>
                  </Col>

                  <Divider />

                  {/* name */}
                  <Col xs={24} sm={24} xl={24}>
                    <Row>
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                      >
                        <img src={Userfill} />
                        {this.state.language == 1 ? "ชื่อ-นามสกุล" : "Name"}
                      </Col>
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                      >
                        {this.state.tit_name + " "}
                        {this.state.fName + " "}
                        {this.state.lName}
                      </Col>
                    </Row>
                  </Col>

                  <Divider />

                  {/* birthday */}
                  <Col xs={24} sm={24} xl={24}>
                    <Row>
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                      >
                        <img src={Daterange} /> {dictProfile[4]}
                      </Col>
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                      >
                        {this.state.birthDay}
                      </Col>
                    </Row>
                  </Col>

                  <Divider />
                  {/* phone and email */}
                  {this.state.language !== "1" && (
                    <Col xs={24} sm={24} xl={24}>
                      <Row>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <img src={Phone} /> {dictProfile[5]}
                          <h5 style={{ paddingLeft: 20 }}>
                            {this.state.language == 1
                              ? "หมายเลขโทรศัพท์/Email"
                              : "Phone number/Email"}
                          </h5>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          className="col-profile-phone-email"
                        >
                          {this.state.PhoneEmailShow}
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {this.state.language !== "1" && <Divider />}
                  {/* repassword */}
                  {/* if tit_id equel thai id is not show */}
                  {this.state.language !== "1" && (
                    <Col xs={24} sm={24} xl={24} className="testaa">
                      <Row>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <div style={{ paddingLeft: 20 }}>
                            {this.state.language == 1
                              ? "เปลี่ยนรหัสผ่านใหม่"
                              : "Reset password"}
                            <h5> *********</h5>
                          </div>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <Link to="/dlt-Smartqueue/changepassword">
                            <Image
                              preview={false}
                              width={22}
                              height={25}
                              src={Triangle}
                            />
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {this.state.language !== "1" && <Divider />}
                </Row>
              </Col>
            </Row>
            {/* if tit_id equel thai id is not show */}
            {this.state.language !== "1" && (
              <div className="container-btnch">
                {this.state.has_booking > 0 ? (
                  <Button className="btn-ch" onClick={() => this.modalalert()}>
                    {dictProfile[6]}
                  </Button>
                ) : (
                  <Link to="/dlt-Smartqueue/profilechange">
                    <Button className="btn-ch">{dictProfile[6]}</Button>
                  </Link>
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Profile;
