/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Input, Select, Checkbox, Modal, Form } from "antd";
import "./css/Information.css";
import Close from "../../../../Icon/close2.svg";
import LogoClose from "../../../../Icon/close .svg";
import LogoSuccess from "../../../../Icon/checkmark.svg";
import sha1 from "sha1";
import md5 from "md5";
import axios from "axios";
import { Link } from "react-router-dom";

const { Option } = Select;
function getDay(n) {
  let Days = [];
  for (let number = 1; number <= n; number++) {
    if (number > 9) {
      Days.push("" + number);
    } else {
      Days.push("0" + number);
    }
  }
  return Days;
}
function getYears(currentYear) {
  let Years = [];
  for (let i = currentYear - 90; i <= currentYear; i += 1) {
    Years.push(i);
  }
  return Years;
}

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dict and language
      dict: require("../../../../dictionary/menudict.json"),
      language: props.location.state.language,
      typeUser: props.location.state.typeUser,
      // form send api
      tit_id: "",
      fName: "",
      lName: "",
      birthDay: "",
      passport: "",
      phone: "",
      email: "",
      line: "",
      password: "",
      comfirmpassword: "",
      // selectperfix
      selectPrefixTH: ["นาย", "นาง", "นางสาว"],
      selectPrefixEN: ["Mr.", "Mrs.", "Miss"],

      // birthday
      day: "",
      month: "",
      years: "",
      checkboxNotBirth: false,

      // select day month year
      defaultSelectDay: [],
      defaultSelecMonth: [],
      defaultSelectYears: [],
      selectDay: getDay(31),
      selectMonthTH: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      selectMonthEN: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      selectYearsTH: getYears(new Date().getFullYear() + 543),
      selectYearsEN: getYears(new Date().getFullYear()),

      // checkEmpty
      checkEmptyPrefix: false,
      checkEmptyFname: false,
      checkEmptyLname: false,
      checkEmptyDay: false,
      checkEmptyMonth: false,
      checkEmptyYears: false,
      checkEmptyCheckbox: false,
      checkEmptyPassport: false,
      checkEmptyPhone: false,
      checkEmptyPassword: false,
      checkEmptyConfirmPassword: false,

      // modal
      isModalVisibleConditions: false,
      checkboxModal: false,
      isModalVisibleSuccess: false,
      isModalVisibleFail: false,
      isModalVisibleALFail: false,
    };
  }
  formRef = React.createRef();
  // onchange dropdown prefix
  handleChangePrefix(value) {
    this.setState({
      tit_id: value,
    });
  }

  // onchange dropdown day
  handleChangeDay(value) {
    this.setState({
      day: value,
      defaultSelectDay: value,
    });
  }

  // onchange dropdown month and algorithum set day follow month
  handleChangeMonth(value) {
    // algorithm check day each month
    if (value == 2) {
      if (this.state.years !== "" && this.state.years % 4 == 0) {
        if (this.state.day > 29) {
          this.formRef.current.resetFields(["day"]);
          this.setState({
            defaultSelectDay: [],
            day: "",
            selectDay: getDay(29),
          });
        } else {
          this.setState({
            selectDay: getDay(29),
          });
        }
      } else {
        if (this.state.day > 28) {
          this.formRef.current.resetFields(["day"]);
          this.setState({
            defaultSelectDay: [],
            day: "",
            selectDay: getDay(28),
          });
        } else {
          this.setState({
            selectDay: getDay(28),
          });
        }
      }
    } else if (value == 4 || value == 6 || value == 9 || value == 11) {
      if (this.state.day > "30") {
        this.formRef.current.resetFields(["day"]);
        this.setState({
          defaultSelectDay: [],
          day: "",
        });
      }
      this.setState({
        selectDay: getDay(30),
      });
    } else {
      this.setState({
        selectDay: getDay(31),
      });
    }

    if (value < 10) {
      var newMonth = value.toString().padStart(2, "0");
    } else {
      var newMonth = value.toString();
    }
    this.setState({
      month: newMonth,
      defaultSelecMonth: newMonth,
    });
  }

  // onchange dropdown years and algorithm set day follow number of day (365 or 366)
  handleChangeYears(value) {
    if (this.state.language == 1) {
      var yearSent = (value - 543).toString();
    } else {
      var yearSent = value.toString();
    }
    if (yearSent % 4 == 0 && this.state.month == "02") {
      if (this.state.day > 29) {
        this.formRef.current.resetFields(["day"]);
        this.setState({
          defaultSelectDay: [],
          day: "",
          selectDay: getDay(29),
        });
      } else {
        this.setState({
          selectDay: getDay(29),
        });
      }
    }

    this.setState({
      years: yearSent,
      defaultSelectYears: yearSent,
    });
  }

  // if checkbox not birth  value day, month, years, --> set 00-00-0000
  onChangecheckboxNotBirth(e) {
    this.formRef.current.resetFields(["day", "month", "years"]);
    this.setState({
      checkboxNotBirth: e.target.checked,
      defaultSelectDay: [],
      defaultSelecMonth: [],
      defaultSelectYears: [],
      day: "00",
      month: "00",
      years: "0000",
    });
  }

  // check id number(in thai)
  PassportChecking(passport) {
    if (passport == undefined || passport == "") {
      return true;
    } else {
      for (var i = 0, sum = 0; i < 12; i++) {
        sum += parseFloat(passport.charAt(i)) * (13 - i);
      }
      if ((11 - (sum % 11)) % 10 == parseFloat(passport.charAt(12))) {
        return true;
      } else {
        return false;
      }
    }
  }
  // check id number(non thai )
  PassportCheckingNonthai(passport) {
    if (passport == undefined || passport == "") {
      return true;
    } else {
      for (var i = 0, sum = 0; i < 12; i++) {
        sum += parseFloat(passport.charAt(i)) * (13 - i);
      }
      if ((11 - (sum % 11)) % 10 == parseFloat(passport.charAt(12))) {
        return true;
      } else {
        return false;
      }
    }
  }

  // check input id number(in thai)
  checkInputPassport = (e) => {
    const checkvalue = /^\d+$/.test(e.target.value);
    if (this.state.typeUser == 1) {
      if (checkvalue) {
        this.setState({
          passport: e.target.value,
        });
      } else {
        this.setState({
          passport: "",
        });
      }
    } else {
      this.setState({
        passport: e.target.value,
      });
    }
  };

  // show border color red  when entering wrong infomation
  showModalConditions() {
    this.setState({
      isModalVisibleConditions: true,
    });
  }
  hideModalConditions() {
    this.setState({
      isModalVisibleConditions: false,
    });
  }
  showModalFail() {
    this.setState({
      isModalVisibleFail: true,
    });
  }
  hideModalFail() {
    this.setState({
      isModalVisibleFail: false,
    });
  }
  showModalALFail() {
    this.setState({
      isModalVisibleALFail: true,
    });
  }
  hideModalALFail() {
    this.setState({
      isModalVisibleALFail: false,
    });
  }
  showModalSuccess() {
    this.setState({
      isModalVisibleSuccess: true,
    });
  }
  hideModalSuccess() {
    this.setState({
      isModalVisibleSuccess: false,
    });
  }

  // function modal
  onCheckboxModalConditions(e) {
    this.setState({
      checkboxModal: e.target.checked,
    });
  }

  // send data to api
  register() {
    try {
      const birthSend =
        this.state.checkboxNotBirth == true
          ? null
          : this.state.years + "-" + this.state.month + "-" + this.state.day;

      // hashPassword
      const md = md5(this.state.password);
      const hashPassword = sha1(md);

      const data = {
        tit_id: this.state.tit_id,
        fname: this.state.fName,
        lname: this.state.lName,
        per_id: this.state.passport,
        birth_date: birthSend,
        phone: this.state.phone,
        email: this.state.email,
        line_id: this.state.line,
        password: hashPassword,
        lang: this.state.language,
      };
      if (this.state.checkboxModal) {
        axios
          .post(process.env.REACT_APP_API_DLT1 + "/user/register", data, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.data == "Already Registed!!") {
              // setModalSwitchPER_ID(true);
              this.hideModalConditions();
              this.showModalALFail();
            } else {
              if (response.data == "wrong format or one of them is null") {
                this.hideModalConditions();
                this.showModalFail();
              } else {
                this.hideModalConditions();
                this.showModalSuccess();
              }
            }
          });
      }
    } catch (error) {}
  }

  onFinish = () => {
    this.showModalConditions();
  };

  render() {
    const dictRegistor =
      this.state.dict[this.state.language].Menu[0].WordPackage[0].Words;

    const dictRegistorModalConditions =
      this.state.dict[this.state.language].Menu[0].WordPackage[0].Modal_Confirm;

    const dictRegistorModalStatus =
      this.state.dict[this.state.language].Menu[0].WordPackage[0].Modal_Status;
    return (
      <div className="content-information">
        <Form
          name="basic"
          onFinish={this.onFinish}
          ref={this.formRef}
          // onFinishFailed={this.onFinishFailed}
          // autoComplete="on"
        >
          {/* information */}
          <Row className="row-information-title">
            <Col
              className="col-information-title"
              offset={2}
              xs={20}
              sm={20}
              xl={20}
            >
              <h1 className="text-title">{dictRegistor[0]}</h1>
              {/* prefix */}
              <Row className="row-information-prefix">
                <Col
                  className="col-information-prefix"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <Row>
                    <h2 className="text-title">{dictRegistor[1]}</h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>

                  <Row>
                    <Col offset={1} xs={20} sm={20} xl={21}>
                      <Form.Item
                        name="tit_id"
                        rules={[
                          {
                            required: true,
                            message:
                              this.state.language == 1
                                ? "กรุณาเลือกคำนำหน้า "
                                : "Please select your prefix!",
                          },
                        ]}
                      >
                        <Select
                          className={"select-prefix"}
                          placeholder={dictRegistor[1]}
                          onChange={(e) => {
                            this.handleChangePrefix(e);
                          }}
                        >
                          {(this.state.language == 1
                            ? this.state.selectPrefixTH
                            : this.state.selectPrefixEN
                          ).map((prefix, index) => {
                            return (
                              <Option key={index} value={index + 1}>
                                {prefix}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* prefix */}

              {/* fname and lname */}
              <Row className="row-information-detail">
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <Row>
                    <h2 className="text-title">{dictRegistor[2]}</h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>
                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item
                        name="fName"
                        rules={[
                          {
                            required: true,
                            message:
                              this.state.language == 1
                                ? "กรุณาใส่ชื่อ"
                                : "Please input your firstname!",
                          },
                        ]}
                      >
                        <Input
                          className={"info-input-Fname"}
                          placeholder={dictRegistor[2]}
                          onChange={(e) =>
                            this.setState({
                              fName: e.target.value,
                              checkEmptyFname: false,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <Row>
                    <h2 className="text-title">{dictRegistor[3]}</h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>

                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message:
                              this.state.language == 1
                                ? "กรุณาใส่นามสกุล"
                                : "Please input your lastname!",
                          },
                        ]}
                      >
                        <Input
                          className={"info-input-Lname"}
                          placeholder={dictRegistor[3]}
                          onChange={(e) =>
                            this.setState({
                              lName: e.target.value,
                              checkEmptyLname: false,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* birth day */}
              <Row className="row-information-detail">
                <Col offset={1} xs={22} sm={22} xl={22}>
                  <Row>
                    <h2 className="text-title">
                      {dictRegistor[5]}/{dictRegistor[6]}/{dictRegistor[7]}
                    </h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>

                  <Row>
                    {/* day */}
                    <Col
                      className="col-information-detail"
                      xs={24}
                      sm={6}
                      xl={5}
                    >
                      <Row>
                        <Col
                          xs={23}
                          sm={{ span: 21, offset: 2 }}
                          xl={{ span: 21, offset: 2 }}
                        >
                          <Form.Item
                            name="day"
                            rules={[
                              {
                                required: !this.state.checkboxNotBirth,
                                message:
                                  this.state.language == 1
                                    ? "กรุณาเลือกวันที่เกิด"
                                    : "Please select your birth date!",
                              },
                            ]}
                          >
                            <Select
                              disabled={
                                this.state.checkboxNotBirth ? true : false
                              }
                              className={"select-day"}
                              value={this.state.defaultSelectDay}
                              placeholder={dictRegistor[5]}
                              style={{ width: "100%", borderRadius: 5 }}
                              onChange={(e) => this.handleChangeDay(e)}
                            >
                              {this.state.selectDay.map((day, key) => {
                                return (
                                  <Option key={key} value={day}>
                                    {day}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    {/* month */}
                    <Col
                      className="col-information-detail"
                      xs={24}
                      sm={6}
                      xl={5}
                    >
                      <Row>
                        <Col xs={23} sm={23} xl={23}>
                          <Form.Item
                            name="month"
                            rules={[
                              {
                                required: !this.state.checkboxNotBirth,
                                message:
                                  this.state.language == 1
                                    ? "กรุณาเลือกเดือนเกิด"
                                    : "Please select your birth month!",
                              },
                            ]}
                          >
                            <Select
                              disabled={
                                this.state.checkboxNotBirth ? true : false
                              }
                              className={"select-month"}
                              placeholder={dictRegistor[6]}
                              style={{ width: "100%", borderRadius: 5 }}
                              onChange={(e) => this.handleChangeMonth(e)}
                            >
                              {(this.state.language == 1
                                ? this.state.selectMonthTH
                                : this.state.selectMonthEN
                              ).map((month, index) => {
                                return (
                                  <Option key={index} value={index + 1}>
                                    {month}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    {/* years */}
                    <Col
                      className="col-information-detail"
                      xs={24}
                      sm={6}
                      xl={6}
                    >
                      <Row>
                        <Col xs={23} sm={22} xl={22}>
                          <Form.Item
                            name="years"
                            rules={[
                              {
                                required: !this.state.checkboxNotBirth,
                                message:
                                  this.state.language == 1
                                    ? "กรุณาเลือกปีเกิด"
                                    : "Please select your birth year!",
                              },
                            ]}
                          >
                            <Select
                              disabled={
                                this.state.checkboxNotBirth ? true : false
                              }
                              className={"select-years"}
                              placeholder={dictRegistor[7]}
                              style={{ width: "100%", borderRadius: 5 }}
                              onChange={(e) => this.handleChangeYears(e)}
                            >
                              {(this.state.language == 1
                                ? this.state.selectYearsTH
                                : this.state.selectYearsEN
                              ).map((Year, index) => {
                                return (
                                  <Option key={index} value={Year}>
                                    {Year}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    {/* checkbox */}
                    <Col
                      className="col-information-detail"
                      xs={24}
                      sm={24}
                      xl={6}
                    >
                      <Row className="row-info-checkbox-birth">
                        <Col
                          className="col-info-input"
                          xs={24}
                          sm={24}
                          xl={{ span: 22, offset: 2 }}
                        >
                          <Row className="row-info-detail-checkbox">
                            <Form.Item>
                              <Checkbox
                                className="checkbox-birth"
                                onChange={(e) =>
                                  this.onChangecheckboxNotBirth(e)
                                }
                              />
                            </Form.Item>
                            <h3 className="text-checkbox">{dictRegistor[8]}</h3>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* passport and phone */}
              <Row className="row-information-detail">
                {/* passport */}
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <Row>
                    <h2 className="text-title">
                      {this.state.typeUser == 1 && this.state.language == 2
                        ? "Non Thai"
                        : dictRegistor[4]}
                    </h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>

                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item
                        name="passport"
                        rules={[
                          {
                            required: true,
                            message:
                              this.state.language == 1
                                ? "กรุณาใส่บัตรประชาชน"
                                : this.state.language == 2 &&
                                  this.state.typeUser == 1
                                ? "Please input your Non thai id!"
                                : "Please input your passport!",
                            pattern:
                              this.state.language == 1
                                ? new RegExp(/[0-9]/)
                                : this.state.language == 2 &&
                                  this.state.typeUser == 1
                                ? new RegExp(/[0-9]/)
                                : new RegExp(/[A-Z0-9]/),
                          },
                          () => ({
                            validator: (_, value) =>
                              this.state.language == 1
                                ? !value ||
                                  !new RegExp(/[0-9]/).test(value) ||
                                  value.length === 13
                                  ? this.PassportChecking(value)
                                    ? Promise.resolve()
                                    : Promise.reject("เลขบัตรประชาชนไม่ถูกต้อง")
                                  : Promise.reject(
                                      "กรุณาใส่เลขบัตรประชาชนให้ครบ"
                                    )
                                : this.state.language == 2 &&
                                  this.state.typeUser == 1
                                ? !value ||
                                  !new RegExp(/[0-9]/).test(value) ||
                                  value.length === 13
                                  ? this.PassportCheckingNonthai(value)
                                    ? Promise.resolve()
                                    : Promise.reject("id card number is wrong")
                                  : Promise.reject(
                                      "please complete your non thai id no.!"
                                    )
                                : !value ||
                                  (value.length >= 7 && value.length <= 9) ||
                                  !new RegExp(/[A-Z0-9]/).test(value)
                                ? Promise.resolve()
                                : Promise.reject(
                                    "please complete your passport number!"
                                  ),
                          }),
                        ]}
                      >
                        <Input
                          maxLength={this.state.typeUser == 1 ? 13 : 9}
                          // type={this.state.typeUser == 1 ? "number" : "Text"}
                          className={"info-input-passport"}
                          placeholder={
                            this.state.typeUser == 1 && this.state.language == 2
                              ? "Non-Thai ID"
                              : dictRegistor[4]
                          }
                          onChange={(e) => this.checkInputPassport(e)}
                          value={this.state.passport}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {/* phone  */}
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <Row>
                    <h2 className="text-title">{dictRegistor[9]}</h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>

                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message:
                              this.state.language == 1
                                ? "กรุณาใส่เบอร์โทรศัพท์ (เฉพาะตัวเลขเท่านั้น)"
                                : "Please input your phone number! (number only)",
                            pattern: new RegExp(/^[0-9]*$/),
                          },

                          () => ({
                            validator: (_, value) =>
                              !value ||
                              value.length == 10 ||
                              !new RegExp(/^[0-9]*$/).test(value)
                                ? Promise.resolve()
                                : Promise.reject(
                                    this.state.language == 1
                                      ? "กรุณาใส่เบอร์โทรศัพท์ให้ครบ"
                                      : "please complete your phone number!"
                                  ),
                          }),
                        ]}
                      >
                        <Input
                          maxLength={10}
                          onWheel={(e) => e.target.blur()}
                          className={"info-input-phone"}
                          placeholder={dictRegistor[9]}
                          onChange={(e) => {
                            if (e.target.value.length <= 10) {
                              this.setState({
                                phone: e.target.value,
                                checkEmptyPhone: false,
                              });
                            }
                          }}
                          value={this.state.phone}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* email and line */}
              <Row className="row-information-detail">
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <h2 className="text-title">{dictRegistor[10]}</h2>
                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item name="email">
                        <Input
                          className="info-input"
                          placeholder={dictRegistor[10]}
                          onChange={(e) =>
                            this.setState({
                              email: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <h2 className="text-title">{dictRegistor[11]}</h2>
                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item name="line">
                        <Input
                          className="info-input"
                          placeholder={dictRegistor[11]}
                          onChange={(e) =>
                            this.setState({
                              line: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* password */}
          <Row className="row-information-title">
            <Col
              className="col-information-title"
              offset={2}
              xs={20}
              sm={20}
              xl={20}
            >
              <h1 className="text-title">{dictRegistor[12]}</h1>

              {/* password and confirm */}
              <Row className="row-information-detail">
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <Row>
                    <h2 className="text-title">
                      {this.state.language == 1 ? "รหัสผ่าน" : "Password"}
                    </h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>

                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message:
                              this.state.language == 1
                                ? "กรุณาใส่รหัสผ่าน"
                                : "Please input your password!",
                            pattern: new RegExp(
                              /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
                            ),
                          },
                        ]}
                      >
                        <Input.Password
                          className={"info-input-password"}
                          placeholder={dictRegistor[12]}
                          autoComplete="on"
                          onChange={(e) =>
                            this.setState({
                              password: e.target.value,
                              checkEmptyPassword: false,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  className="col-information-detail"
                  offset={1}
                  xs={23}
                  sm={11}
                  xl={11}
                >
                  <Row>
                    <h2 className="text-title">{dictRegistor[13]}</h2>
                    <h3 className="Asterisk">*</h3>
                  </Row>

                  <Row>
                    <Col
                      className="col-info-input"
                      offset={1}
                      xs={20}
                      sm={20}
                      xl={21}
                    >
                      <Form.Item
                        name="confirmpassword"
                        rules={[
                          {
                            required: true,
                            message:
                              this.state.language == 1
                                ? "กรุณาใส่รหัสผ่านยืนยัน"
                                : "Please input your confirm password!",
                          },
                          ({ getFieldValue }) => ({
                            validator: (_, value) =>
                              !value || getFieldValue("password") === value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      this.state.language == 1
                                        ? "รหัสผ่านทั้งสองไม่ตรงกัน "
                                        : "both password are not the same, please check your passwords!"
                                    )
                                  ),
                          }),
                        ]}
                      >
                        <Input.Password
                          className={"info-input-confirmpassword"}
                          placeholder={dictRegistor[13]}
                          autoComplete="on"
                          onChange={(e) =>
                            this.setState({
                              comfirmpassword: e.target.value,
                              checkEmptyConfirmPassword: false,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {/* text warning */}
                <Col
                  className="col-information-warning"
                  offset={2}
                  xs={21}
                  sm={21}
                  xl={23}
                >
                  <h4 className="text-warning">{dictRegistor[14]}</h4>
                </Col>
              </Row>
              {/* button */}
              <Row justify="end">
                <Col
                  className="col-information-button"
                  xs={23}
                  sm={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="button-confirm"
                      // onClick={() => this.checkEmpty()}
                    >
                      <p className="p-button-confirm">{dictRegistor[15]}</p>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        {/* modal Conditions */}
        <Modal
          className="modalConditions"
          visible={this.state.isModalVisibleConditions}
          onCancel={() => this.hideModalConditions()}
          maskClosable={false}
          closeIcon={<img src={Close} />}
          footer={null}
        >
          {/* modal title */}
          <Row>
            <Col xs={24} sm={24} xl={24} className="col-title-modal-Conditions">
              <h2 className="top-title-modal">เงื่อนไขการลงทะเบียน</h2>
              <h3 className="sub-title-modal">Conditions for registration</h3>
            </Col>
          </Row>
          {/* modal detail */}
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-detail-modal-Conditions"
            >
              <p className="detail-modal">{dictRegistorModalConditions[2]}</p>
            </Col>
          </Row>
          {/* modal checkbox */}
          <Row>
            <Col
              xs={{ span: 18, offset: 3 }}
              sm={{ span: 18, offset: 3 }}
              xl={{ span: 18, offset: 3 }}
              className="col-detail-modal-Conditions"
            >
              <Row>
                <Checkbox
                  className="checkbox-birth"
                  onChange={(e) => this.onCheckboxModalConditions(e)}
                ></Checkbox>
                <h4 className="text-checkbox-modal">
                  {dictRegistorModalConditions[3]}
                </h4>
              </Row>
            </Col>
          </Row>
          {/* modal button */}
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-button-modal-Conditions"
            >
              <Button
                disabled={this.state.checkboxModal ? false : true}
                className="button-modal-Conditions"
                onClick={() => this.register()}
              >
                <p className="text-button-modal">
                  {dictRegistorModalConditions[4]}
                </p>
              </Button>
            </Col>
          </Row>
        </Modal>
        {/* modalfail */}
        <Modal
          className="modalFail"
          visible={this.state.isModalVisibleFail}
          onCancel={() => this.hideModalFail()}
          closeIcon={<img src={Close} />}
          footer={null}
        >
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modal-logo-fail"
            >
              <img className="LogoClose" src={LogoClose} />
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modal-text-fail"
            >
              <h2 className="text-modal-fail">{dictRegistorModalStatus[0]}</h2>
            </Col>
          </Row>
        </Modal>
        {/* modal Already Registed */}
        <Modal
          className="modalFail"
          visible={this.state.isModalVisibleALFail}
          onCancel={() => this.hideModalALFail()}
          maskClosable={false}
          closeIcon={
            <Link to="/">
              <img src={Close} />
            </Link>
          }
          footer={null}
        >
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modal-logo-fail"
            >
              <img className="LogoClose" src={LogoClose} />
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modal-text-fail"
            >
              <h2 className="text-modal-fail">
                {this.state.language == 1
                  ? `ท่านเคยทำการลงทะเบียบแล้ว โปรดเลือกเมนู เข้าสู่ระบบ หรือ ลืมรหัสผ่าน`
                  : `You have already registered, please choose "log-in" or "forget password" instead.`}
              </h2>
            </Col>
          </Row>
        </Modal>
        {/* modal Success */}
        <Modal
          className="modalSuccess"
          visible={this.state.isModalVisibleSuccess}
          onCancel={() => this.hideModalSuccess()}
          maskClosable={false}
          closeIcon={
            <Link to="/">
              <img src={Close} />
            </Link>
          }
          footer={null}
        >
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modal-logo-success"
            >
              <img className="LogoSuccess" src={LogoSuccess} />
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modal-text-success"
            >
              <h2 className="text-modal-success">
                {dictRegistorModalStatus[1]}
              </h2>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default Information;
