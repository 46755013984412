/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Checkbox, Modal } from "antd";
import Close from "../../../../Icon/close2.svg";
import axios from "axios";
import "./css/ModalSameQ.css";
import CryptoJS from "crypto-js";

export class ModalSameQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      de_type:
        CryptoJS.AES.decrypt(
          localStorage.getItem("role"),
          localStorage.getItem("username")
        ).toString(CryptoJS.enc.Utf8) != 1
          ? "web"
          : "staff",
    };
  }

  async submit() {
    try {
      const data = {
        username: this.props.username,
        timestamp: this.props.dataRebook.boo_datetime,
        boo_code: this.props.dataRebook.boo_code,
        staff_username: this.props.username_Admin,
      };
      const cancle_booking = await axios.post(
        this.props.username_Admin === ""
          ? process.env.REACT_APP_API_DLT1 + "/uWeb/cancelBooking"
          : process.env.REACT_APP_API_DLT1 + "/adWeb/cancelBooking",
        // ? "http://gecc.dlt.go.th:13999/dlt-api1/uWeb/cancelBooking"
        // : "http://gecc.dlt.go.th:13999/dlt-api1/adWeb/cancelBooking",
        data,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const Cancle_BookingJson = await cancle_booking.data;
      if (
        Cancle_BookingJson === "cancel booking by uWeb complete" ||
        Cancle_BookingJson === "cancel booking by adWeb complete"
      ) {
        try {
          const bookingdata = {
            username: this.props.username,
            de_type: this.state.de_type,
            bookdate: this.props.data.Calendar.datebook,
            round: this.props.data.Calendar.round,
            tyw_id: this.props.data.Typework.tyw_id,
            ve_id: this.props.data.VE_type.id,
            lang: this.props.language,
            one_year: this.props.data.Calendar.one_year,
            day_dif: this.props.data.Calendar.day_dif,
            staff_username: this.props.username_Admin,
          };
          const booking = await axios.post(
            process.env.REACT_APP_API_DLT1 + "/booking",
            // "http://gecc.dlt.go.th:13999/dlt-api1/booking",
            bookingdata,
            {
              headers: {
                "Content-type": "application/json",
              },
            }
          );
          const BookingJson = await booking.data;
          if (BookingJson.modal) {
            this.props.language == "1"
              ? this.props.setTextError(BookingJson.msg_th)
              : this.props.setTextError(BookingJson.msg_en);
            this.props.swal_Error();
          } else if (BookingJson.booking_status == "yes") {
            if (
              BookingJson !== undefined &&
              BookingJson.boo_code !== undefined
            ) {
              this.props.setDataSuccess(
                BookingJson.boo_code,
                BookingJson.tyw_comment,
                BookingJson.ticketurl
              );
              this.props.swal();
              // this.props.ModalSuccess(true);
              this.props.ModalSameQ(false);
            } else {
              this.props.language == "1"
                ? this.props.setTextError(BookingJson.msg_th)
                : this.props.setTextError(BookingJson.msg_en);
              this.props.swal_Error();
            }
          } else {
            this.props.swal_Error();
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <Modal
          className="modalSameQ"
          visible={this.props.isModalVisibleSameQ}
          onCancel={() => this.props.ModalSameQ(false)}
          closeIcon={<img src={Close} />}
          footer={null}
        >
          {/* title */}
          <Row justify="center">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 20 }}
              xl={{ span: 20 }}
              className="col-modalSameQ-text-title"
            >
              <h1 className="text-modalSameQ-modal-title">
                {/* {this.props.Modaldict[7]} */}
                {this.props.language == 1 ? "คำเตือน" : "Warning"}
              </h1>
            </Col>
          </Row>
          {/* sub title */}
          <Row justify="center">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 20 }}
              xl={{ span: 20 }}
              className="col-modalSameQ-text-sub"
            >
              <h3 className="text-modalSameQ-modal-sub">
                {this.props.Modaldict[0]}
              </h3>
            </Col>
          </Row>
          {/* text #! */}
          <Row justify="center">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 20 }}
              xl={{ span: 20 }}
              className="col-modalSameQ-text-sub-detail"
            >
              <h3 className="text-modalSameQ-modal-sub-detail">
                {this.props.Modaldict[1]}
              </h3>
            </Col>
          </Row>
          {/* Ticket*/}
          <Row justify="center">
            <Col
              xs={{ span: 22 }}
              sm={{ span: 16 }}
              xl={{ span: 16 }}
              className="col-modalSameQ-ticket"
            >
              <Row justify="center">
                <Col
                  xs={{ span: 20 }}
                  sm={{ span: 21 }}
                  xl={{ span: 21 }}
                  className="col-modalSameQ-ticket-left"
                >
                  <h5 className="text-modalSameQ-ticket-st">
                    {this.props.dataRebook.sit_name}
                  </h5>
                  <h5 className="text-modalSameQ-ticket-nd">
                    {this.props.dataRebook.tyw_name}
                  </h5>
                  <h5 className="text-modalSameQ-ticket-rd">
                    {this.props.date}
                  </h5>
                </Col>
                <Col
                  xs={{ span: 4 }}
                  sm={{ span: 3 }}
                  xl={{ span: 3 }}
                  className="col-modalSameQ-ticket-right"
                ></Col>
              </Row>
            </Col>
          </Row>
          {/* Text #2 */}
          <Row justify="center">
            <Col
              xs={{ span: 22 }}
              sm={{ span: 20 }}
              xl={{ span: 20 }}
              className="col-modalSameQ-text-sup-detail"
            >
              <h3 className="text-modalSameQ-modal-sub-detail-danger">
                {this.props.Modaldict[2]}
              </h3>
            </Col>
          </Row>
          {/* text top Checkbox  */}
          <Row justify="center">
            <Col
              xs={{ span: 22 }}
              sm={{ span: 20 }}
              xl={{ span: 20 }}
              className="col-modalSameQ-text-top-Checkbox"
            >
              <h3 className="text-modalSameQ-top-Checkbox">
                {this.props.Modaldict[3]}
              </h3>
            </Col>
          </Row>
          {/* checkbox */}
          <Row justify="center" className="row-modalSameQ-checkbox">
            <Col
              className="col-modalSameQ-checkbox"
              xs={{ span: 24 }}
              sm={{ span: 20 }}
              xl={{ span: 20 }}
            >
              <Checkbox
                className="checkbox-modalSameQ"
                onChange={(e) => this.props.onChangecheckBoxSameQ(e)}
              >
                <h4 className="text-modalSameQ-checkbox">
                  {this.props.Modaldict[4]}
                </h4>
              </Checkbox>
            </Col>
          </Row>
          {/* button */}
          <Row justify="center">
            <Col
              className="col-modalSameQ-button"
              xs={{ span: 7 }}
              sm={{ span: 5 }}
              xl={{ span: 5 }}
            >
              <Button
                className="button-modalSameQ"
                size="large"
                onClick={() => this.props.ModalSameQ(false)}
              >
                <p className="p-button-modalSameQ">{this.props.Modaldict[6]}</p>
              </Button>
            </Col>
            <Col
              className="col-modalSameQ-button"
              xs={{ span: 7 }}
              sm={{ span: 5 }}
              xl={{ span: 5 }}
            >
              <Button
                disabled={this.props.checkBoxSameQ == false ? true : false}
                className="button-modalSameQ"
                size="large"
                onClick={() => this.submit()}
              >
                <p className="p-button-modalSameQ">{this.props.Modaldict[7]}</p>
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default ModalSameQ;
