/* eslint-disable */
import React, { useContext } from "react";
import { Form, Input, Button, Col, Row, Modal } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Logo from "../../../../Image/Logo.png";
import "./Login.css";
import { Link, Redirect } from "react-router-dom";
import sha1 from "sha1";
import md5 from "md5";
import axios from "axios";
import Close from "../../../../Icon/close2.svg";
import LogoClose from "../../../../Icon/close .svg";
import { withRouter } from "react-router";
import CryptoJS from "crypto-js";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

axios.defaults.withCredentials = true;
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passport: "",
      password: "",
      username: "",
      language: "",
      isModalVisibleFail: false,
      textShowError: "",
      token: "",
      reReCaptCha: false,
    };
  }

  CheckRoute(role) {
    return role === 0
      ? this.props.history.push({
          pathname: "/dlt-Smartqueue",
        })
      : this.props.history.push({
          pathname: "/admin",
        });
  }

  // check id number(in thai)
  PassportChecking(passport) {
    if (passport == undefined || passport == "") {
      return false;
    } else {
      for (var i = 0, sum = 0; i < 12; i++) {
        sum += parseFloat(passport.charAt(i)) * (13 - i);
      }
      if ((11 - (sum % 11)) % 10 == parseFloat(passport.charAt(12))) {
        return true;
      } else {
        return false;
      }
    }
  }

  onLogin = async (e) => {
    e.preventDefault();
    const checkPassport =
      this.state.passport.length === 13
        ? this.PassportChecking(this.state.passport)
        : this.state.passport.length !== 0
        ? true
        : false;
    if (checkPassport) {
      try {
        var md = md5(this.state.password);
        var hashPassword = sha1(md);
        const data = {
          username: this.state.passport,
          password: hashPassword,
          response: this.state.token,
        };
        await axios
          .post(process.env.REACT_APP_API_DLT2 + "/web/login", data, {
            mode: "cors",
            headers: {
              "Content-type": "application/json",
            },
            withCredentials: true,
          })
          .then((response) => {
            // console.log(response);
            this.checkRoute(response.data);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == "404") {
                // Alert.alert('เลขบัตรประชาชนหรือรหัสผ่านผิด');
                this.showModalFail();
                this.setState({
                  textShowError:
                    "ข้อมูลหรือรหัสผ่านไม่ถูกต้อง / Incorrect information or password",
                  reReCaptCha: true,
                });
              }
            }
          });
      } catch (error) {
        console.log("error", error);
      }
    } else {
      this.showModalFail();
      this.setState({
        textShowError:
          "เลขบัตรประชาชนไม่ถูกต้อง กรุณาตรวจสอบเลขบัตร หรือลงทะเบียนใหม่ด้วยข้อมูลที่ถูกต้อง / ID number is invalid, please validate your ID number or re-register with the correct information.",
      });
    }
  };

  checkRoute(data) {
    var roledata = data.role;
    var lang;
    if (data.lang == "EN") {
      lang = CryptoJS.AES.encrypt("2", data.username);
    } else {
      lang = CryptoJS.AES.encrypt("1", data.username);
    }
    localStorage.setItem("username", data.username);
    localStorage.setItem("lang", lang);
    let check = roledata == 0 ? "/dlt-Smartqueue" : "/admin";
    var role = CryptoJS.AES.encrypt(roledata.toString(), data.username);
    localStorage.setItem("role", role);
    this.props.history.push({
      pathname: check,
    });
  }

  componentDidMount() {
    var checkCookie = localStorage.getItem("Cookie");
    this.setState({
      isShowCookie: checkCookie,
    });
  }
  showModalFail() {
    this.setState({
      isModalVisibleFail: true,
    });
  }
  hideModalFail() {
    this.setState({
      isModalVisibleFail: false,
    });
  }

  handleVerify = (tokenresponse) => {
    this.setState({
      token: tokenresponse,
    });
  };
  render() {
    return (
      <div className="content-login">
        {/* row-logo */}
        <Row className="row-Logo-login" justify="center" align="top">
          {/* xs < 576 sm >=576 xl >=1200 */}
          <Col className="col-Logo-login" xs={20} sm={8} xl={6}>
            <img className="logo-login-page" src={Logo} />
          </Col>
        </Row>
        <Row className="row-Content-login" justify="center" align="top">
          <Col className="col-Content-login" xs={22} sm={10} xl={8}>
            <Form className="login-form">
              {/* input passport */}
              <Form.Item
                className="username"
                name="username"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input your Passport / Non Thai identification card !",
                  },
                ]}
              >
                <Input
                  className="form-input"
                  maxLength={13}
                  onChange={(e) =>
                    this.setState({
                      passport: e.target.value,
                    })
                  }
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Passport/ Non Thai identification card "
                />
              </Form.Item>
              {/* input password */}
              <Form.Item
                className="password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password !",
                  },
                ]}
              >
                <Input.Password
                  className="form-input"
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                    })
                  }
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  autoComplete="on"
                  placeholder="password"
                />
              </Form.Item>
              {/* forgot */}
              <Form.Item className="form-forgot-loginpasge">
                <Link to="forgotpassword">
                  <p className="login-form-forgot">Forget password</p>
                </Link>
              </Form.Item>
              {/* button  */}
              <div>
                <GoogleReCaptcha
                  action={"submit"}
                  siteKey={`${process.env.REACT_APP_SITE_KEY}`}
                  onVerify={this.handleVerify}
                />
              </div>
              <Form.Item className="form-button-loginpage">
                {/* <Link to="/register">
                  <Button className="login-form-button">
                    <b className="text-top">ลงทะเบียน</b>
                    <br />
                    <b className="text-sup">Register</b>
                  </Button>
                </Link> */}
                <Button
                  htmlType="submit"
                  className="login-form-button"
                  onClick={this.onLogin}
                >
                  <b className="text-top">Login</b>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {/* modalfail */}
        <Modal
          className="login-modalFail"
          visible={this.state.isModalVisibleFail}
          onCancel={() => this.hideModalFail()}
          closeIcon={<img src={Close} />}
          footer={null}
        >
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-login-modal-logo-fail"
            >
              <img className="LogoClose-login" src={LogoClose} />
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-login-modal-text-fail"
            >
              <h2 className="text-login-modal-fail">
                {this.state.textShowError}
              </h2>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Login);
