/* eslint-disable */
import React from "react";
import { Button, Col, Row } from "antd";
import Logo from "../../../../Image/Logo.png";
import "./css/DocumentaryForeigner.css";
import { Link } from "react-router-dom";

class DocumentaryForeigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { language: "" };
  }

  render() {
    return (
      <div className="content-foreigner">
        <Row className="row-Logo-foreigner" justify="center" align="top">
          <Col className="col-Logo-foreigner" xs={20} sm={8} xl={6}>
            <img className="logo-foreigner-page" src={Logo} />
          </Col>
        </Row>
        <Row className="row-Content-foreigner" justify="center" align="top">
          <Col className="col-Content-foreigner" xs={22} sm={10} xl={8}>
            <p className="top-text-foreigner">Documentary type</p>
            <p className="sub-text-foreigner">For Foreigner</p>
            <Col
              className="col-button-foreigner"
              offset={1}
              xs={22}
              sm={22}
              xl={22}
            >
              <Link
                to={{
                  pathname: "/information",
                  state: { language: 2, typeUser: 2 },
                }}
              >
                <Button className="button-foreigner">
                  <p className="p-button-foreigner">Passport</p>
                </Button>
              </Link>
            </Col>
            <Col
              className="col-button-foreigner"
              offset={1}
              xs={22}
              sm={22}
              xl={22}
            >
              <Link
                to={{
                  pathname: "/information",
                  state: { language: 2, typeUser: 1 },
                }}
              >
                <Button className="button-foreigner">
                  <p className="p-button-foreigner">
                    Non Thai Identification Card
                  </p>
                </Button>
              </Link>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DocumentaryForeigner;
