/* eslint-disable */
import React from "react";
import { Divider, Button, Modal, Row, Col, Checkbox } from "antd";
import "../../../../css/Home/Info.css";
import QRCode from "qrcode.react";

class InfoQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  convertDateTime = (time, time_unit) => {
    // เวลาไทย
    let time_thai = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    let text_time = "";
    let new_time = parseInt(time.substring(0, 2));
    if (new_time >= 12) {
      const isLargeNumber = (element) => element == new_time;
      text_time = time_thai.findIndex(isLargeNumber) + ":" + time_unit + " PM";
    } else {
      text_time = time.substring(0, 2) + ":" + time_unit + " AM";
    }
    return text_time;
  };

  checkThreeYear = () => {
    let str_result = "";
    if (this.props.language === 1) {
      let result_th =
        this.props.data.Calendar.day_dif >= 1095
          ? "ใบอนุญาตขับรถของท่านสิ้นอายุเกิน 3 ปี"
          : "ใบอนุญาตขับรถของท่านสิ้นอายุเกิน 1 ปี";
      str_result = result_th;
    } else {
      let result_en =
        this.props.data.Calendar.day_dif >= 1095
          ? "your driver's license has expired more than 3 year."
          : "your driver's license has expired more than 1 year.";
      str_result = result_en;
    }
    return str_result;
  };

  render() {
    return (
      <div>
        <Row justify="center" className="row-header-info">
          {/* header */}
          <Col
            className="col-header-info"
            xs={{ span: 20 }}
            sm={{ span: 20 }}
            xl={{ span: 20 }}
          >
            <Col>
              <h2 className="title-header-info">{this.props.dict[0]}</h2>
            </Col>
          </Col>
          {/* content */}
          <Col
            className="col-content-info"
            xs={{ span: 20 }}
            sm={{ span: 20 }}
            xl={{ span: 20 }}
          >
            <Row justify="center">
              {/* detail */}
              <Col
                className="col-content-info-detail"
                xs={{ span: 22 }}
                sm={{ span: 18 }}
                xl={{ span: 18 }}
              >
                {/* name and passport */}
                <Row className="aaaaa">
                  <Col
                    xs={{ span: 20 }}
                    sm={{ span: 11 }}
                    xl={{ span: 12 }}
                    className="col-content-info-detail-name"
                  >
                    <p className="p-topic">{this.props.dict[5]} </p>
                    <p className="p-value">
                      {this.props.data.ConfirmQueue.user.per_fname} &nbsp;
                      {this.props.data.ConfirmQueue.user.per_lname}
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 20 }}
                    sm={{ span: 11 }}
                    xl={{ span: 12 }}
                    className="col-content-info-detail-passport"
                  >
                    <p className="p-topic">{this.props.dict[6]}</p>
                    <p className="p-value">
                      {this.props.data.ConfirmQueue.user.per_id}
                    </p>
                  </Col>
                </Row>
                <Divider style={{ marginTop: 0, marginBottom: 14 }} />
                {/* date and time */}
                <Row justify="start">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    xl={{ span: 12 }}
                    className="col-content-info-detail-date"
                  >
                    <p className="p-topic">{this.props.dict[2]}</p>
                    <p className="p-value">
                      {this.props.data.Calendar.formatdate}
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    xl={{ span: 12 }}
                    className="col-content-info-detail-time"
                  >
                    <p className="p-topic">{this.props.dict[4]}</p>
                    <p className="p-value">
                      {this.props.language === 1
                        ? this.props.data.Calendar.round
                        : this.convertDateTime(
                            this.props.data.Calendar.round.substring(0, 5),
                            this.props.data.Calendar.round
                              .substring(0, 5)
                              .substring(3, 5)
                          ) +
                          " - " +
                          this.convertDateTime(
                            this.props.data.Calendar.round.substring(8, 13),
                            this.props.data.Calendar.round
                              .substring(8, 13)
                              .substring(3, 5)
                          )}
                    </p>
                  </Col>
                </Row>
                {/* tyw */}
                <Row>
                  <Col
                    xs={{ span: 20 }}
                    sm={{ span: 22 }}
                    xl={{ span: 22 }}
                    className="col-content-info-detail-tyw"
                  >
                    {/* <Row> */}
                    <p className="p-topic">{this.props.dict[12]}</p>
                    <p className="p-value">
                      {this.props.data.Typework.tyw_name}
                    </p>
                    {/* </Row> */}
                  </Col>
                </Row>
                {/* site */}
                <Row>
                  <Col
                    xs={{ span: 20 }}
                    sm={{ span: 22 }}
                    xl={{ span: 22 }}
                    className="col-content-info-detail-site"
                  >
                    <p className="p-topic">{this.props.dict[7]}</p>
                    <p className="p-value">{this.props.data.site.site_name}</p>
                  </Col>
                </Row>
                {/* site */}
                {this.props.data.Calendar.one_year ? (
                  <Row>
                    <Col
                      xs={{ span: 20 }}
                      sm={{ span: 22 }}
                      xl={{ span: 22 }}
                      className="col-content-info-detail-site"
                    >
                      <p className="p-topic">
                        {" "}
                        {this.props.language === 1 ? "หมายเหตุ" : "Warning"}
                      </p>
                      <p className="p-value">
                        {/* {this.props.language === 1
                          ? "ใบอนุญาตขับรถของท่านสิ้นอายุเกิน 1 ปี"
                          : "your driver's license has expired more than 1 year."} */}
                        {this.checkThreeYear()}
                      </p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        {/* QRcode */}
        <Row justify="center">
          <Col
            className="col-content-info-qrcode"
            xs={{ span: 22 }}
            sm={{ span: 6 }}
            xl={{ span: 4 }}
          >
            <Row justify="center">
              <Col
                xs={{ span: 16 }}
                sm={{ span: 22 }}
                xl={{ span: 20 }}
                className="col-content-info-detail-qrcode"
              >
                <p className="p-topic">
                  {this.props.language === 1
                    ? "สแกนข้อมูลการจองคิว"
                    : "Scan booking information."}
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={{ span: 12, offset: 6 }}
                sm={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
                className="col-content-info-detail-qrcode-img"
              >
                <QRCode
                  className="QRcode"
                  value={this.props.data.ConfirmQueue.ticketurl}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 22 }}
                xl={{ span: 22 }}
                className="col-content-info-detail-qrcode"
              >
                <p className="p-topic">
                  {this.props.data.ConfirmQueue.boo_code}
                </p>
                <p className="p-topic">QRCode</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            className="col-button-info margin-l-320px"
            xs={{ span: 16 }}
            sm={{ span: 8 }}
            xl={{ span: 8 }}
          >
            <Button
              className="button-info"
              size="large"
              // style={{ margin: "0 8px" }}
              onClick={() =>
                this.props.next(
                  1,
                  null,
                  this.props.language === 1
                    ? "เอกสารที่ต้องเตรียม"
                    : "Required Documents",
                  this.props.language === 1
                    ? "รายละเอียดเอกสารที่ต้องเตรียม"
                    : "Required documents."
                )
              }
            >
              <span>{this.props.dict[1]}</span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default InfoQueue;
