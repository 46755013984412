import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <BrowserRouter basename="dltsmartqueue">
    <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_SITE_KEY}`}>
      <App />
    </GoogleReCaptchaProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
