/* eslint-disable */
import React from "react";
import { Button, Col, Row } from "antd";
import Logo from "../../../../Image/Logo.png";
import "./css/ForgotPassword.css";
import { Link } from "react-router-dom";
class ForgotPassword extends React.Component {
  render() {
    return (
      <div className="content-ForgotPassword">
        <div className="animation-slide-page-regis">
          <Row className="row-Logo-ForgotPassword" justify="center" align="top">
            <Col className="col-Logo-ForgotPassword" xs={20} sm={8} xl={6}>
              <img className="logo-ForgotPassword-page" src={Logo} />
              <p className="top-text-doc-ForgotPassword">Forget password</p>
              {/* <p className="sub-text-doc-ForgotPassword">ลืมรหัสผ่าน</p> */}
            </Col>
          </Row>
          <Row
            className="row-Content-ForgotPassword"
            justify="center"
            align="top"
          >
            <Col className="col-Content-ForgotPassword" xs={22} sm={10} xl={8}>
              <p className="top-text-doc-ForgotPassword">
                Please select your documentary type.
              </p>
              {/* <p className="sub-text-doc-ForgotPassword">
              กรุณาเลือกเพื่อสร้างรหัสผ่านใหม่
                
              </p> */}
              {/* <Col
                className="col-button-doc-ForgotPassword"
                offset={1}
                xs={22}
                sm={22}
                xl={22}
              >
                <Link
                  to={{
                    pathname: "/informationforgotForgotPassword",
                    state: { language: 1 },
                  }}
                >
                  <Button className="button-doc-ForgotPassword">
                    <p className="p-button-doc-ForgotPassword">
                      เลขประจำตัวประชาชน
                    </p>
                  </Button>
                </Link>
              </Col> */}
              <Col
                className="col-button-doc-ForgotPassword"
                offset={1}
                xs={22}
                sm={22}
                xl={22}
              >
                <Link
                  to={{
                    pathname: "/informationforgotForgotPassword",
                    state: { language: 2 },
                  }}
                >
                  <Button className="button-doc-ForgotPassword">
                    <p className="p-button-doc-ForgotPassword">Passport</p>
                  </Button>
                </Link>
              </Col>
              <Col
                className="col-button-doc-ForgotPassword"
                offset={1}
                xs={22}
                sm={22}
                xl={22}
              >
                <Link
                  to={{
                    pathname: "/informationforgotForgotPassword",
                    state: { language: 2 },
                  }}
                >
                  <Button className="button-doc-ForgotPassword">
                    <p className="p-button-doc-ForgotPassword">
                      Non Thai identification card
                    </p>
                  </Button>
                </Link>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
