/* eslint-disable */
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Menu, Grid, Layout } from "antd";
import { withRouter } from "react-router";
import axios from "axios";

const { useBreakpoint } = Grid;
const SubMenu = Menu.SubMenu;

const LeftMenu = (props) => {
  const [currentKey, setCurrentKey] = useState(props.match.path);

  const handleClick = (e) => {
    setCurrentKey(e.key);
  };

  const Onpress_logout = async () => {
    try {
      const clear_cookie = await axios.get(
        process.env.REACT_APP_API_DLT2 + "/clearCookie",
        {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const CookieJson = await clear_cookie.data;
      if (CookieJson === "OK") {
        localStorage.removeItem("username");
        localStorage.removeItem("role");
        localStorage.removeItem("lang");
        props.history.push({
          pathname: "/main",
        });
      } else {
        props.history.push({
          pathname: "/main",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { md } = useBreakpoint();
  const Lang = props.language;
  return (
    <Menu
      mode={"inline"}
      onClick={handleClick}
      selectedKeys={props.location.pathname}
      // className={md ? "menu-item-size" : ""}
      // className={md ? "menu-item-size-mobile" : ""}
      // style={{ width: "500px" }}
      // className="list-menu-nav"
    >
      <Menu.Item key={props.match.path} onClick={() => props.closeNav()}>
        <span>{Lang == 1 ? "หน้าแรก" : "Home"}</span>
        <Link to={props.match.path}></Link>
      </Menu.Item>
      <Menu.Item
        key={props.match.path + "/profile"}
        onClick={() => props.closeNav()}
      >
        <span>{Lang == 1 ? "ข้อมูลของฉัน" : "Profile"}</span>
        <Link to={props.match.path + "/profile"} />
      </Menu.Item>
      <SubMenu
        key="/sub"
        title={<span>{Lang == 1 ? "คิวของฉัน" : "History"}</span>}
      >
        <Menu.Item
          key={props.match.path + "/history"}
          onClick={() => props.closeNav()}
        >
          <span>{Lang == 1 ? "คิวที่รอดำเนินการ" : "Waiting for service"}</span>
          <Link to={props.match.path + "/history"} />
        </Menu.Item>
        <Menu.Item
          key={props.match.path + "/historycancel"}
          onClick={() => props.closeNav()}
        >
          <span>{Lang == 1 ? "คิวที่ยกเลิก" : "Cancel Booking"}</span>
          <Link to={props.match.path + "/historycancel"} />
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/logout" onClick={() => Onpress_logout()}>
        <span>{Lang == 1 ? "ออกจากระบบ" : "Logout"}</span>
        <Link to="/" />
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(LeftMenu);
