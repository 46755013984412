/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Image, Divider, Button, Input, Modal, Row, Col, Select } from "antd";
import Logo from "../../img/logo/LogoDLT.png";
import "./profile.css";
import axios from "axios";
import Close1 from "../../img/icon/close 1.svg";
import Close2 from "../../img/icon/close2.svg";
import CheckMark from "../../img/icon/check mark.svg";
import Userfill from "../../img/icon/User_fill.svg";
import Daterange from "../../img/icon/Date_range.svg";
import Phone from "../../img/icon/phone.svg";
import Email from "../../img/icon/email.svg";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
const { Option } = Select;
function getDay(n) {
  let Days = [];
  for (let number = 1; number <= n; number++) {
    if (number > 9) {
      Days.push("" + number);
    } else {
      Days.push("0" + number);
    }
  }
  return Days;
}
function getYears(currentYear) {
  let Years = [];
  for (let i = currentYear - 90; i <= currentYear; i += 1) {
    Years.push(i);
  }
  return Years;
}

class ProfileChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dict: require("../../../dictionary/menudict.json"),
      language: 1 /* props.location.state.language */,
      modalVisible: false,
      modalcheck: false,
      tit_id: "",
      fname: "",
      lname: "",
      day: "",
      month: "",
      years: "",
      phone: "",
      email: "",
      loading: true,
      defaultSelectDay: [],
      defaultSelecMonth: [],
      defaultSelectYears: [],
      selectDay: getDay(31),
      selectMonthTH: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      selectMonthEN: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      selectYearsTH: getYears(new Date().getFullYear() + 543),
      selectYearsEN: getYears(new Date().getFullYear()),
      username: this.props.username,
      language: this.props.language,

      //default value
      defaultFname: "",
      defaultLname: "",
      defaultPhone: "",
      defaultEmail: "",
      defaultDay: "",
      defaultMonth: "",
      defaultYears: "",

      //checkEmpty
      checkEmptyPhone: false,
    };
  }

  handleChangeDay(value) {
    this.setState({
      day: value,
      defaultSelectDay: value,
      checkEmptyDay: false,
    });
  }
  handleChangeMonth(value) {
    // algorithm check day each month
    if (value == 2) {
      this.setState({
        defaultSelectDay: [],
        day: "",
        selectDay: getDay(28),
      });
    } else if (value == 4 || value == 6 || value == 9 || value == 11) {
      if (this.state.day > "30") {
        this.setState({
          defaultSelectDay: [],
          day: "",
        });
      }
      this.setState({
        selectDay: getDay(30),
      });
    } else {
      this.setState({
        selectDay: getDay(31),
      });
    }

    if (value < 10) {
      var newMonth = value.toString().padStart(2, "0");
    } else {
      var newMonth = value.toString();
    }
    this.setState({
      month: newMonth,
      defaultSelecMonth: newMonth,
      checkEmptyMonth: false,
    });
  }
  handleChangeYears(value) {
    if ((value - 543) % 4 == 0 && this.state.month == "02") {
      if (this.state.day > 28) {
        this.setState({
          defaultSelectDay: [],
          day: "",
          // defaultYears: "",
        });
      }
      this.setState({
        selectDay: getDay(29),
      });
    } else {
      if (this.state.day > 28) {
        this.setState({
          defaultSelectDay: [],
          // defaultYears: "",
          day: "",
        });
      }
      this.setState({
        selectDay: getDay(28),
      });
    }

    if (this.state.language == 1) {
      var yearSent = (value - 543).toString();
    } else {
      var yearSent = value.toString();
    }
    this.setState({
      years: yearSent,
      defaultSelectYears: yearSent,
      checkEmptyYears: false,
      // defaultYears: yearSent,
    });
  }

  _click() {
    this.setState({ modalVisible: false });
  }

  _clickpass() {
    this.setState({ modalcheck: false });
  }

  checkdefalt() {
    this.CheckPhone();
  }

  CheckPhone() {
    if (this.state.phone !== "") {
      if (this.state.phone.length !== 10) {
        this.setState({
          checkEmptyPhone: true,
        });
      } else {
        this.changeInformation();
      }
    } else {
      this.changeInformation();
    }
  }

  changeInformation() {
    try {
      // month send
      if (this.state.month == "") {
        // check defaultMonth before send value month to birth_date follow format (yyyy-mm-dd)
        if (this.state.defaultMonth < 10 && this.state.defaultMonth !== null) {
          var newMonth = this.state.defaultMonth.toString().padStart(2, "0");
        } else {
          var newMonth = this.state.defaultMonth;
        }
      } else {
        // check Month(value change) before send value month to birth_date follow format (yyyy-mm-dd)
        if (this.state.month < 10) {
          var newMonth = this.state.month.toString().padStart(2, "0");
        } else {
          var newMonth = this.state.month;
        }
      }
      // year send (check language thai all eng for chanage fomat years to AD(ค.ส.),check value years has changed ?)
      const yearsSend =
        this.state.language == 1
          ? this.state.years == ""
            ? this.state.defaultYears - 543
            : this.state.years == ""
            ? this.state.defaultYears
            : this.state.years
          : this.state.years;

      // day send (check value day has changed ?)
      const daySend =
        this.state.day == "" ? this.state.defaultDay : this.state.day;

      // birth_day send
      const birth_date = yearsSend + "-" + newMonth + "-" + daySend;

      // data all send to api
      const datachange = {
        username: this.state.username,
        fname:
          this.state.fname == "" ? this.state.defaultFname : this.state.fname,
        lname:
          this.state.lname == "" ? this.state.defaultLname : this.state.lname,
        birth_date:
          this.state.defaultDay === null &&
          this.state.defaultMonth === null &&
          this.state.defaultYears === null
            ? this.state.day == "" ||
              this.state.month == "" ||
              this.state.years == ""
              ? null
              : birth_date
            : this.state.day == "" &&
              this.state.month == "" &&
              this.state.years == ""
            ? null
            : birth_date,
        phone:
          this.state.phone == "" ? this.state.defaultPhone : this.state.phone,
        email:
          this.state.email == "" ? this.state.defaultEmail : this.state.email,
      };
      axios
        .post(
          process.env.REACT_APP_API_DLT1 + "/updatePersonalProfile",
          datachange,
          {}
        )
        .then((response) => {
          if (response.data == "wrong format or one of them is null") {
            this.setState({ modalcheck: true });
          } else {
            this.setState({ modalVisible: true });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    const data = { username: this.state.username };
    try {
      axios
        .post(process.env.REACT_APP_API_DLT1 + "/getPersonalProfile", data, {})
        .then((response) => {
          if (response.data !== "wrong username") {
            // no birth date not show placeholder
            if (response.data.per_birthday === null) {
              this.setState({
                tit_id: response.data.per_id,
                defaultFname: response.data.per_fname,
                defaultLname: response.data.per_lname,
                defaultPhone: response.data.per_phone,
                defaultEmail: response.data.per_email,
                defaultDay: null,
                defaultMonth: null,
                defaultYears: null,
                loading: false,
              });
            } else {
              // have birth date show birth date at placeholder
              this.setState({
                tit_id: response.data.per_id,
                defaultFname: response.data.per_fname,
                defaultLname: response.data.per_lname,
                defaultPhone: response.data.per_phone,
                defaultEmail: response.data.per_email,
                defaultDay:
                  new Date(response.data.per_birthday).getDate() < 10
                    ? `0${new Date(response.data.per_birthday).getDate()}`
                    : new Date(response.data.per_birthday).getDate(),
                defaultMonth:
                  new Date(response.data.per_birthday).getMonth() + 1,
                defaultYears:
                  this.state.language == 1
                    ? new Date(response.data.per_birthday).getFullYear() + 543
                    : new Date(response.data.per_birthday).getFullYear(),
                loading: false,
              });
              // set Leap Year follow default birth date
              this.handleChangeDay(this.state.defaultDay);
              this.handleChangeMonth(this.state.defaultMonth);
              this.handleChangeYears(this.state.defaultYears);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 0
    ) {
      return <Redirect to="/main" />;
    }
    const dictProfileEdit =
      this.state.dict[this.state.language].Menu[3].WordPackage[2].Words;

    const dictProfileModalwarning =
      this.state.dict[this.state.language].Menu[3].WordPackage[0].Words;
    return (
      <div className="container-profile">
        <Modal
          className="modalchangeprofile"
          footer={null}
          closable={false}
          visible={this.state.modalVisible}
        >
          <div>
            <Link to="/dlt-Smartqueue/profile">
              <img
                className="btn-close"
                src={Close2}
                onClick={() => this._click()}
              />
            </Link>
          </div>

          <div className="divbe">
            <Link to="/dlt-Smartqueue/profile">
              <div className="iconcheckmark">
                <img src={CheckMark} onClick={() => this._click()} />
              </div>
              <div className="modalsave">{dictProfileEdit[7]}</div>
              <Button className="btn-be" onClick={() => this._click()}>
                {dictProfileModalwarning[2]}
              </Button>
            </Link>
          </div>
        </Modal>
        <Modal
          className="modalprofile"
          footer={null}
          closable={false}
          visible={this.state.modalcheck}
        >
          <div>
            <img
              className="btn-close"
              src={Close2}
              onClick={() => this._clickpass()}
            />
          </div>

          <div className="divbe">
            <div className="iconcheckmark">
              <img src={Close1} />
            </div>
            <div className="modalsave">
              {this.state.language == 1
                ? "กรอกข้อมูลไม่ครบถ้วน"
                : "Incomplete information"}
            </div>
            <Button className="btn-be" onClick={() => this._clickpass()}>
              {dictProfileModalwarning[2]}
            </Button>
          </div>
        </Modal>

        <Row justify="center" className="row-profile-main">
          <Col xs={20} sm={20} xl={20} className="bg-profile-content">
            <Row justify="center" align="top">
              <Col xs={5} sm={3} xl={3}>
                <Image className="logo" preview={false} src={Logo} />
              </Col>
            </Row>
            <div className="titleprofile">
              {this.state.language == 1
                ? "ข้อมูลส่วนตัว"
                : "Personal information"}
            </div>
            {this.state.loading === false ? (
              <div>
                {/* content */}
                <Row>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 12, offset: 6 }}
                    xl={{ span: 12, offset: 6 }}
                  >
                    <Row>
                      <Divider />
                      {/* per id */}
                      <Col xs={24} sm={24} xl={24}>
                        <Row>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <div style={{ paddingLeft: 20 }}>
                              <Row>
                                {this.state.language == 1
                                  ? "เลขประจำตัวประชาชน"
                                  : "Your ID"}
                              </Row>
                            </div>
                          </Col>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            {this.state.tit_id}
                          </Col>
                        </Row>
                      </Col>

                      <Divider />
                      {/* name */}
                      <Col xs={24} sm={24} xl={24}>
                        <Row>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <img src={Userfill} /> {dictProfileEdit[1]}-
                            {dictProfileEdit[2]}
                          </Col>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 5, offset: 0 }}
                          >
                            <Input
                              className="inputfnameprofile"
                              placeholder={this.state.defaultFname}
                              onChange={(e) =>
                                this.setState({
                                  fname: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col
                            xs={{ span: 12, offset: 12 }}
                            sm={{ span: 12, offset: 12 }}
                            xl={{ span: 5, offset: 1 }}
                          >
                            <Input
                              className="inputlnameprofile"
                              placeholder={this.state.defaultLname}
                              onChange={(e) =>
                                this.setState({
                                  lname: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Divider />

                      {/* birth date */}
                      <Col xs={24} sm={24} xl={24}>
                        <Row>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <img src={Daterange} /> {dictProfileEdit[3]}
                          </Col>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <Row>
                              {/* day */}
                              <Col
                                xs={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                xl={{ span: 8, offset: 0 }}
                                className="col-profileChange-birthdate"
                              >
                                <Select
                                  className="dateinput"
                                  placeholder={
                                    this.state.language == 1 ? "วัน" : "Days"
                                  }
                                  onChange={(e) => this.handleChangeDay(e)}
                                  defaultValue={this.state.defaultDay}
                                >
                                  {this.state.selectDay.map((day, key) => {
                                    return (
                                      <Option key={key} value={day}>
                                        {day}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Col>
                              {/* month */}
                              <Col
                                xs={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                xl={{ span: 8, offset: 0 }}
                                className="col-profileChange-birthdate"
                              >
                                <Select
                                  className="monthinput"
                                  placeholder={
                                    this.state.language == 1 ? "เดือน" : "Month"
                                  }
                                  onChange={(e) => this.handleChangeMonth(e)}
                                  defaultValue={
                                    this.state.language == 1
                                      ? this.state.selectMonthTH[
                                          this.state.defaultMonth - 1
                                        ]
                                      : this.state.selectMonthEN[
                                          this.state.defaultMonth - 1
                                        ]
                                  }
                                >
                                  {(this.state.language == 1
                                    ? this.state.selectMonthTH
                                    : this.state.selectMonthEN
                                  ).map((month, index) => {
                                    return (
                                      <Option key={index} value={index + 1}>
                                        {month}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Col>
                              {/* years */}
                              <Col
                                xs={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                xl={{ span: 8, offset: 0 }}
                                className="col-profileChange-birthdate"
                              >
                                <Select
                                  disabled={
                                    this.state.checkboxNotBirth ? true : false
                                  }
                                  className="yearsinput"
                                  placeholder={
                                    this.state.language == 1 ? "ปี" : "Years"
                                  }
                                  onChange={(e) => this.handleChangeYears(e)}
                                  defaultValue={this.state.defaultYears}
                                >
                                  {(this.state.language == 1
                                    ? this.state.selectYearsTH
                                    : this.state.selectYearsEN
                                  ).map((Year, index) => {
                                    return (
                                      <Option key={index} value={Year}>
                                        {Year}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Divider />
                      {/* phone */}
                      <Col xs={24} sm={24} xl={24}>
                        <Row>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <img src={Phone} /> {dictProfileEdit[4]}
                            <h5 style={{ paddingLeft: 20 }}>
                              {this.state.language == 1
                                ? "หมายเลขโทรศัพท์"
                                : "Phone number"}
                            </h5>
                          </Col>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <Input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder={this.state.defaultPhone}
                              className={
                                "profile-input-phone" +
                                (this.state.checkEmptyPhone ? "show" : "")
                              }
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  this.setState({
                                    phone: e.target.value,
                                    checkEmptyPhone: false,
                                  });
                                }
                              }}
                              value={this.state.phone}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Divider />
                      {/* email */}
                      <Col xs={24} sm={24} xl={24}>
                        <Row>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <img src={Email} />
                            {dictProfileEdit[4]}
                            <h5 style={{ paddingLeft: 20 }}>
                              {dictProfileEdit[6]}
                            </h5>
                          </Col>

                          <Col
                            xs={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                          >
                            <Input
                              className="inputemailprofile"
                              placeholder={this.state.defaultEmail}
                              onChange={(e) =>
                                this.setState({
                                  email: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Divider />
                    </Row>
                  </Col>
                </Row>
                {/* button */}
                <div className="container-btnch">
                  <Col xs={24} sm={24} xl={24}>
                    <Row>
                      <Col
                        xs={{ span: 5, offset: 2 }}
                        sm={{ span: 5, offset: 6 }}
                        md={{ span: 5, offset: 6 }}
                        lg={{ span: 5, offset: 7 }}
                        xl={{ span: 5, offset: 7 }}
                      >
                        <Link to="/dlt-Smartqueue/profile">
                          <Button className="btn-cancel">
                            {this.state.language == 1 ? "ยกเลิก" : "Cancel"}
                          </Button>
                        </Link>
                      </Col>
                      <Col
                        xs={{ span: 1, offset: 3 }}
                        sm={{ span: 1, offset: 0 }}
                        md={{ span: 1, offset: 0 }}
                        lg={{ span: 1, offset: 0 }}
                        xl={{ span: 1, offset: 0 }}
                      >
                        <Button
                          className="btn-ch"
                          onClick={() => this.checkdefalt()}
                        >
                          {dictProfileEdit[7]}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            ) : (
              <div className="size-loadding">
                <ReactLoading type="spinningBubbles" color={"#2f0465"} />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProfileChange;
