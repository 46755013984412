/* eslint-disable */
import React from "react";
import { List, Input, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import ReactLoading from "react-loading";

class Office extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      hasMore: true,
      txt_search: "",
      language: this.props.language,
    };
  }
  // process.env.REACT_APP_API_DLT1 + "/getSite/" + this.props.language,

  async componentDidMount() {
    try {
      const site = await axios.get(
        process.env.REACT_APP_API_DLT1 + "/getSite/" + this.props.language,
        {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const siteJson = await site.data;
      this.setState({
        data: siteJson,
        loading: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <p className="title">{this.props.dict[0]}</p>
        <p className="subtitle">{this.props.dict[1]}</p>
        <div className="container_office">
          <div className="Search">
            <Input
              className="input-Search-office"
              placeholder={this.props.dict[2]}
              suffix={<SearchOutlined />}
              onChange={(e) => {
                this.setState(() => {
                  this.state.txt_search = e.target.value;
                  return this.state;
                });
              }}
            />
          </div>
          {this.state.loading === true ? (
            <List className="listItem" itemLayout="vertical">
              {this.state.data
                .filter((item) => {
                  let new_item = JSON.stringify(item.sit_name);
                  let lowerItem = new_item.toLowerCase();
                  if (this.state.txt_search === "") {
                    return item;
                  } else if (
                    lowerItem.includes(this.state.txt_search.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item) => (
                  <List.Item
                    className={item.app_open === 1 ? "item" : "disable-item"}
                    key={item.sit_id.toString()}
                    onClick={() => {
                      item.app_open === 1
                        ? [
                            this.props.next(
                              1,
                              {
                                site: {
                                  site_id: item.sit_id,
                                  site_name: item.sit_name,
                                },
                              },
                              this.props.language === 1
                                ? "ประเภทบริการ"
                                : "Service type",
                              this.props.language === 1
                                ? "เลือกประเภทรับบริการ"
                                : "Select service type."
                            ),
                          ]
                        : null;
                    }}
                  >
                    <p className="site">{item.sit_name}</p>
                    {/* {item.app_open === 0 && (
                      <p className="site warning-officer">
                        {this.props.dict[3]}
                      </p>
                    )} */}
                  </List.Item>
                ))}
            </List>
          ) : (
            <div className="size-loadding">
              <ReactLoading type="spinningBubbles" color={"#2f0465"} />
            </div>
          )}
          {this.state.language === 2 && (
            <Row justify="center" align="center" className="ttt">
              <div className="dot-warning" />
              <span className="warning-officer">: {this.props.dict[3]}</span>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default Office;
