/* eslint-disable */
import React from "react";
import { Row, Col, Select, Checkbox, Button, Form } from "antd";
import "../../../../css/Home/Information.css";

const { Option } = Select;

let Month = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

let Month_EN = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nDay: 31,
      valueDay: null,
      valueMonth: null,
      indexMonth: null,
      valueYear: null,
      color_valueDay: false,
      color_valueMonth: false,
      color_valueYear: false,
      nDay_2: 31,
      valueDay_2: null,
      valueMonth_2: null,
      indexMonth_2: null,
      valueYear_2: null,
      color_valueDay_2: false,
      color_valueMonth_2: false,
      color_valueYear_2: false,
      checkBox_1: false,
      checkBox_2: false,
      checkForm: false,
    };
  }

  Day(number) {
    let day = [];
    for (let i = 1; i <= number; i++) {
      if (i < 10) {
        day.push("0" + i);
      } else {
        day.push(i + "");
      }
    }
    return day;
  }

  Year() {
    let year = [];
    let lang = this.props.language === 1 ? 543 : 0;
    let getYear = new Date().getFullYear();
    for (let i = getYear - 6; i <= getYear + 6; i++) {
      year.push(i + lang);
    }
    return year;
  }

  formatDate(day) {
    let newFormat;
    if (day < 10) {
      newFormat = "0" + day;
    } else {
      newFormat = "" + day;
    }
    return newFormat;
  }

  setData() {
    let data;
    let month = parseInt(this.state.indexMonth) + 1;
    let year =
      this.props.language === 1
        ? this.state.valueYear - 543
        : this.state.valueYear;
    let format =
      year + "" + "-" + this.formatDate(month) + "" + "-" + this.state.valueDay;
    let month_2 = parseInt(this.state.indexMonth_2) + 1;
    let year_2 =
      this.props.language === 1
        ? this.state.valueYear_2 - 543
        : this.state.valueYear_2;
    let format_2 =
      year_2 +
      "" +
      "-" +
      this.formatDate(month_2) +
      "" +
      "-" +
      this.state.valueDay_2;
    if (
      this.props.showType.VE_type.Car === "รถยนต์ และ รถจักรยานยนต์" ||
      this.props.showType.VE_type.Car === "Car and Motocycle"
    ) {
      if (this.state.checkBox_1 === true && this.state.checkBox_2 === true) {
        data = {
          Info: [
            {
              date: null,
            },
          ],
        };
      } else if (this.state.checkBox_1 === true) {
        data = {
          Info: [
            {
              date: null,
            },
            {
              date: format_2,
            },
          ],
        };
      } else if (this.state.checkBox_2 === true) {
        data = {
          Info: [
            {
              date: format,
            },
            {
              date: null,
            },
          ],
        };
      } else {
        data = {
          Info: [
            {
              date: format,
            },
            {
              date: format_2,
            },
          ],
        };
      }
    } else {
      if (this.state.checkBox_1 === false) {
        data = {
          Info: [
            {
              date: format,
            },
          ],
        };
      } else {
        data = {
          Info: [
            {
              date: null,
            },
          ],
        };
      }
    }
    return data;
  }

  CheckValue() {
    let count = 0;
    if (this.props.showType.VE_type.Car === "รถยนต์ และ รถจักรยานยนต์") {
      if (this.state.checkBox_1 === false) {
        if (this.state.valueDay === null) {
          count++;
          this.setState({
            color_valueDay: true,
          });
        }
        if (this.state.valueMonth === null) {
          count++;
          this.setState({
            color_valueMonth: true,
          });
        }
        if (this.state.valueYear === null) {
          count++;
          this.setState({
            color_valueYear: true,
          });
        }
      }

      if (this.state.checkBox_2 === false) {
        if (this.state.valueDay_2 === null) {
          count++;
          this.setState({
            color_valueDay_2: true,
          });
        }
        if (this.state.valueMonth_2 === null) {
          count++;
          this.setState({
            color_valueMonth_2: true,
          });
        }
        if (this.state.valueYear_2 === null) {
          count++;
          this.setState({
            color_valueYear_2: true,
          });
        }
      }
    } else {
      if (this.state.checkBox_1 === false) {
        if (this.state.valueDay === null) {
          count++;
          this.setState({
            color_valueDay: true,
          });
        }
        if (this.state.valueMonth === null) {
          count++;
          this.setState({
            color_valueMonth: true,
          });
        }
        if (this.state.valueYear === null) {
          count++;
          this.setState({
            color_valueYear: true,
          });
        }
      }
    }
    if (count === 0) {
      return this.props.next(
        1,
        this.setData(),
        this.props.language === 1 ? "ประเภทงาน" : "Select the type of work",
        this.props.language === 1
          ? "กรุณาเลือกประเภทงานที่ต้องการ"
          : "Select the type of work to receive the service."
      );
    } else {
      return null;
    }
  }

  setColorCheckValue(value) {
    let check =
      value === false
        ? "neumorphic neumorphic--pressed defaultcheck"
        : "neumorphic neumorphic--pressed checkValue";
    return check;
  }

  setDefaultCar_lang(carTH, carEN) {
    if (this.props.language === 1) {
      return carTH;
    } else {
      return carEN;
    }
  }

  render() {
    let day = this.Day(this.state.nDay);
    let day_2 = this.Day(this.state.nDay_2);
    let Year = this.Year();
    return (
      <div>
        <h2 className="title">{this.props.dict[0]}</h2>
        <Row justify="center" className="height-info-component">
          <Col
            xl={{ span: 22, offset: 2 }}
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
          >
            <Row justify="center">
              <Col className="information_title" xl={12} xs={20} sm={15}>
                <p>{this.props.dict[1]}</p>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={24} sm={15} xl={12}>
                <Col>
                  <Row justify="space-between" className="information_title">
                    <Col className="information_title" xs={8} sm={6} xl={5}>
                      <Select
                        placeholder={this.props.language === 1 ? "วัน" : "Day"}
                        bordered={false}
                        className={this.setColorCheckValue(
                          this.state.color_valueDay
                        )}
                        style={{ width: "100%" }}
                        dropdownStyle={{ borderRadius: 20 }}
                        onChange={(value, index) => {
                          this.setState({
                            valueDay: value,
                            checkBox_1: false,
                            color_valueDay: false,
                            checkBox_1: false,
                          });
                        }}
                        value={this.state.valueDay}
                      >
                        {day.map((item) => (
                          <Option key={item} className="option" value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col className="information_title" xs={8} sm={6} xl={8}>
                      <Select
                        placeholder={
                          this.props.language === 1 ? "เดือน" : "Month"
                        }
                        bordered={false}
                        className={this.setColorCheckValue(
                          this.state.color_valueMonth
                        )}
                        style={{ width: "100%" }}
                        dropdownStyle={{ borderRadius: 20 }}
                        onChange={(value, index) => {
                          if (parseInt(index.key) != 1) {
                            this.setState({
                              nDay: Days[parseInt(index.key)],
                            });
                          } else {
                            if (this.state.valueDay >= 28) {
                              this.setState({
                                valueDay: null,
                              });
                            }
                            this.setState({
                              nDay: Days[parseInt(index.key)],
                            });
                          }
                          this.setState({
                            valueMonth: value,
                            indexMonth: index.key,
                            checkBox_1: false,
                            color_valueMonth: false,
                            checkBox_1: false,
                          });
                        }}
                        value={this.state.valueMonth}
                      >
                        {this.props.language === 1
                          ? Month.map((item, index) => (
                              <Option
                                key={index}
                                className="option"
                                value={item}
                              >
                                {item}
                              </Option>
                            ))
                          : Month_EN.map((item, index) => (
                              <Option
                                key={index}
                                className="option"
                                value={item}
                              >
                                {item}
                              </Option>
                            ))}
                      </Select>
                    </Col>
                    <Col className="information_title" xs={8} sm={6} xl={6}>
                      <Select
                        placeholder={this.props.language === 1 ? "ปี" : "Year"}
                        bordered={false}
                        className={this.setColorCheckValue(
                          this.state.color_valueYear
                        )}
                        style={{ width: "100%" }}
                        dropdownStyle={{ borderRadius: 20 }}
                        onChange={(value, index) => {
                          let year =
                            this.props.language === 1
                              ? parseInt(value) - 543
                              : parseInt(value);
                          if (this.state.indexMonth === "1") {
                            if (
                              (year % 4 == 0 && year % 100 != 0) ||
                              year % 400 == 0
                            ) {
                              this.setState({
                                nDay: 29,
                              });
                            } else {
                              this.setState({
                                nDay: 28,
                              });
                            }
                          }
                          this.setState({
                            valueYear: value,
                            checkBox_1: false,
                            color_valueYear: false,
                            checkBox_1: false,
                          });
                        }}
                        value={this.state.valueYear}
                      >
                        {Year.map((item, index) => (
                          <Option key={item} className="option" value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row justify="center" style={{ paddingTop: "2%" }}>
              <Col className="information_title" xl={12} xs={20} sm={15}>
                <p>{this.props.dict[2]}</p>
              </Col>
            </Row>
            <Row justify="center">
              <Col xl={12} xs={20} sm={15}>
                <Row justify="space-between" className="information_title">
                  <Col className="information_title">
                    <Select
                      placeholder="เลือกรถ"
                      bordered={false}
                      className="neumorphic neumorphic--pressed"
                      style={{ width: 700 }}
                      dropdownStyle={{ borderRadius: 20 }}
                      defaultValue={
                        this.props.showType.VE_type.Car ===
                          "รถยนต์ และ รถจักรยานยนต์" ||
                        this.props.showType.VE_type.Car === "Car and Motocycle"
                          ? this.setDefaultCar_lang(
                              "รถจักรยานยนต์",
                              "Motocycle"
                            )
                          : this.props.showType.VE_type.Car
                      }
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center" style={{ paddingTop: "2%" }}>
              <Col className="information_title" xl={12} sm={15}>
                <Checkbox
                  checked={this.state.checkBox_1}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      this.setState({
                        valueDay: null,
                        valueMonth: null,
                        valueYear: null,
                        checkBox_1: e.target.checked,
                        color_valueDay: false,
                        color_valueMonth: false,
                        color_valueYear: false,
                      });
                    } else {
                      this.setState({
                        checkBox_1: e.target.checked,
                      });
                    }
                  }}
                >
                  <p>{this.props.dict[3]}</p>
                </Checkbox>
              </Col>
            </Row>

            {this.props.showType.VE_type.Car === "รถยนต์ และ รถจักรยานยนต์" ||
            this.props.showType.VE_type.Car === "Car and Motocycle" ? (
              <div>
                <Row justify="center" style={{ paddingTop: "2%" }}>
                  <Col className="information_title" xl={12} sm={15}>
                    <p>{this.props.dict[1]}</p>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xs={24} sm={15} xl={12}>
                    <Col className="asdsad">
                      <Row
                        justify="space-between"
                        className="information_title"
                      >
                        <Col className="information_title" xs={8} sm={6} xl={5}>
                          <Select
                            placeholder={
                              this.props.language === 1 ? "วัน" : "Day"
                            }
                            bordered={false}
                            className={this.setColorCheckValue(
                              this.state.color_valueDay_2
                            )}
                            style={{ width: "100%" }}
                            dropdownStyle={{ borderRadius: 20 }}
                            onChange={(value, index) => {
                              this.setState({
                                valueDay_2: value,
                                color_valueDay_2: false,
                                checkBox_2: false,
                              });
                            }}
                            value={this.state.valueDay_2}
                          >
                            {day_2.map((item) => (
                              <Option
                                key={item}
                                className="option"
                                value={item}
                              >
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col className="information_title" xs={8} sm={6} xl={8}>
                          <Select
                            placeholder={
                              this.props.language === 1 ? "เดือน" : "Month"
                            }
                            bordered={false}
                            className={this.setColorCheckValue(
                              this.state.color_valueMonth_2
                            )}
                            style={{ width: "100%" }}
                            dropdownStyle={{ borderRadius: 20 }}
                            onChange={(value, index) => {
                              if (parseInt(index.key) != 1) {
                                this.setState({
                                  nDay_2: Days[parseInt(index.key)],
                                });
                              } else {
                                if (this.state.valueDay_2 >= 28) {
                                  this.setState({
                                    valueDay_2: null,
                                  });
                                }
                                this.setState({
                                  nDay_2: Days[parseInt(index.key)],
                                });
                              }
                              this.setState({
                                valueMonth_2: value,
                                indexMonth_2: index.key,
                                color_valueMonth_2: false,
                                checkBox_2: false,
                              });
                            }}
                            value={this.state.valueMonth_2}
                          >
                            {this.props.language === 1
                              ? Month.map((item, index) => (
                                  <Option
                                    key={index}
                                    className="option"
                                    value={item}
                                  >
                                    {item}
                                  </Option>
                                ))
                              : Month_EN.map((item, index) => (
                                  <Option
                                    key={index}
                                    className="option"
                                    value={item}
                                  >
                                    {item}
                                  </Option>
                                ))}
                          </Select>
                        </Col>
                        <Col className="information_title" xs={8} sm={6} xl={6}>
                          <Select
                            placeholder={
                              this.props.language === 1 ? "ปี" : "Year"
                            }
                            bordered={false}
                            className={this.setColorCheckValue(
                              this.state.color_valueYear_2
                            )}
                            style={{ width: "100%" }}
                            dropdownStyle={{ borderRadius: 20 }}
                            onChange={(value, index) => {
                              let year =
                                this.props.language === 1
                                  ? parseInt(value) - 543
                                  : parseInt(value);
                              if (this.state.indexMonth_2 === "1") {
                                if (
                                  (year % 4 == 0 && year % 100 != 0) ||
                                  year % 400 == 0
                                ) {
                                  this.setState({
                                    nDay_2: 29,
                                  });
                                } else {
                                  this.setState({
                                    nDay_2: 28,
                                  });
                                }
                              }
                              this.setState({
                                valueYear_2: value,
                                color_valueYear_2: false,
                                checkBox_2: false,
                              });
                            }}
                            value={this.state.valueYear_2}
                          >
                            {Year.map((item, index) => (
                              <Option
                                key={item}
                                className="option"
                                value={item}
                              >
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <Row justify="center" style={{ paddingTop: "2%" }}>
                  <Col className="information_title" xl={12} sm={15}>
                    <p>{this.props.dict[2]}</p>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xl={12} xs={20} sm={15}>
                    <Row justify="space-between" className="information_title">
                      <Col className="information_title">
                        <Select
                          // placeholder="วัน"
                          bordered={false}
                          className="neumorphic neumorphic--pressed"
                          style={{ width: 700 }}
                          dropdownStyle={{ borderRadius: 20 }}
                          defaultValue={
                            this.props.showType.VE_type.Car ===
                              "รถยนต์ และ รถจักรยานยนต์" ||
                            this.props.showType.VE_type.Car ===
                              "Car and Motocycle"
                              ? this.setDefaultCar_lang("รถยนต์", "Car")
                              : this.props.showType.VE_type.Car
                          }
                          disabled
                        ></Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center" style={{ paddingTop: "2%" }}>
                  <Col className="information_title" xl={12} sm={15}>
                    <Checkbox
                      checked={this.state.checkBox_2}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          this.setState({
                            valueDay_2: null,
                            valueMonth_2: null,
                            valueYear_2: null,
                            checkBox_2: e.target.checked,
                            color_valueDay_2: false,
                            color_valueMonth_2: false,
                            color_valueYear_2: false,
                          });
                        } else {
                          this.setState({
                            checkBox_2: e.target.checked,
                          });
                        }
                      }}
                    >
                      <p>{this.props.dict[3]}</p>
                    </Checkbox>
                  </Col>
                </Row>
              </div>
            ) : null}
            {this.props.current === 5 && (
              <div className="steps-action-info-home">
                <Button
                  className="back-btn"
                  size="large"
                  // style={{ margin: "0 8px" }}
                  onClick={() => this.props.prev()}
                >
                  <span>{this.props.dict[4]}</span>
                </Button>
                <Button
                  className="back-btn"
                  size="large"
                  style={{ margin: "0 8px" }}
                  onClick={() => this.CheckValue()}
                >
                  <span>{this.props.dict[5]}</span>
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Information;
