/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Input, Select, Checkbox, Modal } from "antd";
import Close from "../../../../Icon/close2.svg";
import "./css/ModalWarning.css";
import axios from "axios";
import CryptoJS from "crypto-js";

export class ModalWarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      de_type:
        CryptoJS.AES.decrypt(
          localStorage.getItem("role"),
          localStorage.getItem("username")
        ).toString(CryptoJS.enc.Utf8) != 1
          ? "web"
          : "staff",
    };
  }

  formatDMY(dateString) {
    let allDate = dateString.split("-");
    let year = parseInt(allDate[0]) + 543 + "";
    let newformat = allDate[2] + "/" + allDate[1] + "/" + year.substring(2, 4);
    return newformat;
  }

  async submit() {
    try {
      const data = {
        username: this.props.username,
        de_type: this.state.de_type,
        bookdate: this.props.data.Calendar.datebook,
        round: this.props.data.Calendar.round,
        tyw_id: this.props.data.Typework.tyw_id,
        ve_id: this.props.data.VE_type.id,
        lang: this.props.language,
        one_year: this.props.data.Calendar.one_year,
        day_dif: this.props.data.Calendar.day_dif,
        staff_username: this.props.username_Admin,
      };
      const booking = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/booking",
        // "http://gecc.dlt.go.th:13999/dlt-api1/booking",
        data,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const BookingJson = await booking.data;
      if (BookingJson.modal) {
        this.props.language == "1"
          ? this.props.setTextError(BookingJson.msg_th)
          : this.props.setTextError(BookingJson.msg_en);
        this.props.swal_Error();
      } else if (BookingJson.booking_status == "no") {
        this.props.ModalSameQ(true);
        let dmy = this.formatDMY(
          BookingJson.sets[0].boo_datebook.substring(0, 10)
        );
        this.props.setDataRebook(BookingJson.sets[0], dmy);
      }
      // else if (BookingJson.booking_status == "full") {
      //   this.props.swal_Error();
      // } else if (BookingJson == "reach maximum booking per day") {
      //   this.Modal_CheckLimit(true);
      // }
      else {
        if (BookingJson !== undefined) {
          if (BookingJson.boo_code !== undefined) {
            this.props.swal();
            this.props.setDataSuccess(
              BookingJson.boo_code,
              BookingJson.tyw_comment,
              BookingJson.ticketurl
            );
          } else {
            this.props.language == "1"
              ? this.props.setTextError(BookingJson.msg_th)
              : this.props.setTextError(BookingJson.msg_en);
            this.props.swal_Error();
          }
        } else {
          this.props.language == "1"
            ? this.props.setTextError(BookingJson.msg_th)
            : this.props.setTextError(BookingJson.msg_en);
          this.props.swal_Error();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <Modal
          className="modalWarning"
          visible={this.props.isModalVisibleWarning}
          onCancel={() => this.props.ModalWarning(false)}
          closeIcon={<img src={Close} />}
          footer={null}
        >
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modalWarning-text-top"
            >
              <h1 className="text-modalWarning-modal">
                {this.props.Modaldict[7]}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modalWarning-text-sup"
            >
              <h3 className="text-modalWarning-modal-detail">
                {this.props.Modaldict[2]}
              </h3>
            </Col>
          </Row>
          {/* checkbox */}
          <Row className="row-modalWarning-checkbox">
            <Col
              className="col-modalWarning-checkbox"
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
            >
              <Checkbox
                className="checkbox-modalWarning"
                onChange={(e) => this.props.onChangecheckBoxWarning(e)}
              >
                <h4 className="text-modalWarning-checkbox">
                  {this.props.Modaldict[3]}
                </h4>
              </Checkbox>
            </Col>
          </Row>
          {/* text  */}
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modalWarning-text-sup"
            >
              <h3 className="text-modalWarning-modal">
                {this.props.Modaldict[8]}
              </h3>
            </Col>
          </Row>
          {/* button */}
          <Row justify="center">
            <Col
              className="col-modalWarning-button"
              xs={{ span: 7 }}
              sm={{ span: 5 }}
              xl={{ span: 5 }}
            >
              <Button
                className="button-modalWarning"
                size="large"
                onClick={() => this.props.ModalWarning(false)}
              >
                <p className="p-button-modalWarning">
                  {this.props.Modaldict[6]}
                </p>
              </Button>
            </Col>
            <Col
              className="col-modalWarning-button"
              xs={{ span: 7 }}
              sm={{ span: 5 }}
              xl={{ span: 5 }}
            >
              <Button
                disabled={this.props.checkBoxWarning == false ? true : false}
                className="button-modalWarning"
                size="large"
                onClick={() => [this.props.ModalWarning(false), this.submit()]}
              >
                <p className="p-button-modalWarning">
                  {this.props.Modaldict[9]}
                </p>
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default ModalWarning;
