/* eslint-disable */
import { Modal, Carousel, Col } from "antd";
import React, { Component } from "react";
import Close from "../../../img/icon/close2.svg";
import axios from "axios";
import "react-alice-carousel/lib/alice-carousel.css";
import "./css/SiteModal.css";

class SiteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      images: [],
    };
  }
  _click() {
    this.setState({ modalVisible: false });
  }

  componentDidMount() {
    const data = {
      sit_id: this.props.sit_id,
      frontend: true,
    };
    try {
      axios
        .post(
          process.env.REACT_APP_API_DLT1 + "/extra/getAnnounceFromSite",
          data,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({
              modalVisible: false,
            });
          } else {
            this.setState({
              images: response.data,
              modalVisible: true,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <Modal
          visible={this.state.modalVisible}
          footer={null}
          onCancel={() => this._click()}
          closeIcon={<img src={Close} />}
        >
          <div className="titlemodal-public">
            {this.props.language == 1
              ? "ประชาสัมพันธ์สำนักงาน"
              : "Office Announcement"}
          </div>
          <div className="picture-modal-public">
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Carousel autoplay>
                {this.state.images.map((src, index) => (
                  <div key={index.toString()} className="boxslide">
                    <img src={src} className="boxslide" />
                  </div>
                ))}
              </Carousel>
            </Col>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SiteModal;
