/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Input, Select, Checkbox, Modal } from "antd";
import Close from "../../../../Icon/close2.svg";
import "./css/Modal.css";
// import ImgDetail from "../../../../Image/ImgDetail.svg";

export class Modal1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal
          className="modal-component"
          visible={this.props.isModal}
          onCancel={() => this.props.Modal_One(false)}
          closeIcon={<img src={Close} />}
          footer={null}
        >
          <Row>
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xl={{ span: 22, offset: 1 }}
              className="col-modal-component-text-top"
            >
              <h1 className="text-modal-component-modal">
                {this.props.Modaldict[0]}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modal-component-text-sup"
            >
              <h3 className="text-modal-component-modal">
                {this.props.Modaldict[1]}
                {/* ท่านสามารถทำรายการจองคิวได้ 1 รายการ ต่อ 1
                หมวดประเภทใบอนุญาตขับขี่ เช่น
                หากท่านเลือกการจองคิวใบอนุญาตส่วนบุคคลแล้วท่านจะไม่สามารถจองคิวเพิ่มในประเภทงานใบอนุญาตส่วนบุคคลได้อีก */}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col
              className="col-modal-component-button"
              // xs={{ span: 22, offset: 1 }}
              // sm={{ span: 8, offset: 8 }}
              // xl={{ span: 5, offset: 10 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xl={{ span: 22, offset: 1 }}
            >
              <Button
                className="button-modal-component"
                onClick={() => [
                  this.props.Modal_One(false),
                  this.props.next(
                    1,
                    {
                      Calendar: {
                        datebook: this.props.dateBook,
                        formatdate: this.props.formatdate,
                        round: this.props.round,
                        count: this.props.count,
                        Maxcount: this.props.Maxcount,
                        one_year: this.props.one_year,
                        day_dif: this.props.day_dif,
                      },
                    },
                    this.props.language === 1
                      ? "ยืนยันการจองคิว"
                      : "Confirm booking",
                    this.props.language === 1
                      ? "ตรวจสอบข้อมูลการจองคิว"
                      : "Check booking information."
                  ),
                ]}
              >
                <p className="p-button-modal-component">
                  {this.props.Modaldict[2]}
                </p>
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default Modal1;
