/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Input, Select, Checkbox, Modal } from "antd";
import Close from "../../../../Icon/close2.svg";
import "./css/Modal.css";

export class Modal3 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        className="modal-component"
        visible={this.props.isModal}
        onCancel={() => [
          this.props.Modal_Three(false),
          this.props.showRoundOpen(false),
          this.props.setSelectDay(),
        ]}
        closeIcon={<img src={Close} />}
        footer={null}
      >
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
            className="col-modal-component-text-top"
          >
            <h1 className="text-modal-component-modal">
              {this.props.ModaldictWarning[7]}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
            className="col-modal-component-text-sup"
          >
            <h4 className="text-modal-component-modal">
              {this.props.day_dif >= 1095
                ? this.props.ModaldictWarning[10]
                : this.props.ModaldictWarning[1]}
              {/* {this.props.ModaldictWarning[1]} */}
            </h4>
          </Col>
        </Row>
        {/* checkbox */}
        <Row className="row-modal-component-checkbox">
          <Col
            className="col-modal-component-input"
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
          >
            <Row className="row-modal-component-detail-checkbox">
              <Checkbox
                className="checkbox-modal-component"
                onChange={(e) => this.props.onChangecheckBoxSubmit(e)}
              ></Checkbox>
              <h4 className="text-modal-component-checkbox">
                {this.props.ModaldictWarning[3]}
              </h4>
            </Row>
          </Col>
        </Row>
        {/* button */}
        <Row>
          <Col
            className="col-modal-component-button"
            // xs={{ span: 22, offset: 1 }}
            // sm={{ span: 8, offset: 8 }}
            // xl={{ span: 8, offset: 8 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <Button
              disabled={
                this.props.checkBoxSubmitModal_2 == false ? true : false
              }
              className="button-modal-check"
              size="large"
              onClick={() => [
                this.props.Modal_Three(false),
                this.props.showRoundOpen(true),
              ]}
            >
              <p className="p-button-modal-component">
                {this.props.ModaldictWarning[4]}
              </p>
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default Modal3;
