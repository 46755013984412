/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Checkbox, Modal } from "antd";
import Close from "../../../../Icon/close2.svg";
import "./css/Modal.css";

export class Modal4 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        className="modal-component"
        visible={this.props.isModal}
        onCancel={() => this.props.Modal_Four(false)}
        closeIcon={<img src={Close} />}
        footer={null}
      >
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
            className="col-modal-component-text-top"
          >
            <h1 className="text-modal-component-modal">
              {this.props.ModaldictWarning[7]}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
            className="col-modal-component-text-sup"
          >
            <h3 className="text-modal-component-modal">
              {/* {this.props.ModaldictWarning[1]} */}
              {this.props.language == 1
                ? "วันที่เลือกไม่สามารถจองคิวได้ เนื่องจากมีบัตรใบใดใบหนึ่งยังไม่ถึง ระยะเวลาดำเนินการล่วงหน้า โดยการจองคิวล่วงหน้าจะจองได้ไม่เกิน 180 วันก่อนใบอนุญาตสิ้นอายุ"
                : "The selected date cannot be reserved. Because one of the cards has not reached the pre-processing period. By booking the queue in advance, reservations can be made no later than 180 days before the license expires."}
            </h3>
          </Col>
        </Row>
        {/* ห้ามจอง */}
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
            className="col-modal-component-text-top"
          >
            <h4 className="text-modal-component-modal-alert">
              {this.props.language == 1
                ? "โปรดทำการจองคิวแบบ 1 ใบแทน"
                : " Please reserve the queue for 1 ticket instead"}
            </h4>
          </Col>
        </Row>
        {/* checkbox */}
        <Row className="row-modal-component-checkbox">
          <Col
            className="col-modal-component-input"
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
          >
            <Row className="row-modal-component-detail-checkbox">
              <Checkbox
                className="checkbox-modal-component"
                onChange={(e) => this.props.onChangecheckBoxSubmit(e)}
              ></Checkbox>
              <h4 className="text-modal-component-checkbox">
                {this.props.ModaldictWarning[3]}
              </h4>
            </Row>
          </Col>
        </Row>
        {/* button */}
        <Row>
          <Col
            className="col-modal-component-button"
            // xs={{ span: 22, offset: 1 }}
            // sm={{ span: 8, offset: 8 }}
            // xl={{ span: 8, offset: 8 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <Button
              disabled={
                this.props.checkBoxSubmitModal_3 == false ? true : false
              }
              className="button-modal-check"
              size="large"
              onClick={() => this.props.Modal_Four(false)}
            >
              <p className="p-button-modal-component">
                {this.props.ModaldictWarning[4]}
              </p>
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default Modal4;
