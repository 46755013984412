/* eslint-disable */
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import axios from "axios";
import { withRouter } from "react-router";

const { useBreakpoint } = Grid;
const SubMenu = Menu.SubMenu;

const LeftMenu = (props) => {
  const [currentKey, setCurrentKey] = useState("/admin");

  const handleClick = (e) => {
    setCurrentKey(e.key);
  };

  const Onpress_logout = async () => {
    try {
      const clear_cookie = await axios.get(
        process.env.REACT_APP_API_DLT2 + "/clearCookie",
        {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const CookieJson = await clear_cookie.data;
      if (CookieJson === "OK") {
        localStorage.removeItem("username");
        localStorage.removeItem("role");
        localStorage.removeItem("lang");
        props.history.push({
          pathname: "/main",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { md } = useBreakpoint();
  const Lang = props.language;
  return (
    <Menu
      mode={md ? "horizontal" : "inline"}
      onClick={handleClick}
      selectedKeys={props.location.pathname}
      className="list-menu-nav-admin"
    >
      <Menu.Item key={props.match.path}>
        <span>{Lang == 1 ? "เเอดมิน" : "Admin"}</span>
        <Link to={props.match.path} />
      </Menu.Item>
      <Menu.Item key="/logout" onClick={() => Onpress_logout()}>
        <span>{Lang == 1 ? "ออกจากระบบ" : "Logout"}</span>
        <Link to="/main" />
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(LeftMenu);
