/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Input, Modal, Rate } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import Close from "../../../../Icon/close2.svg";
import "./css/ModalRating.css";

export class ModalRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      star: 5,
      ratingComment: "",
    };
  }

  async submit() {
    try {
      const data = {
        username: this.props.username,
        boo_code: this.props.data.ConfirmQueue.boo_code,
        site_id: this.props.data.site.site_id,
        score: this.state.star,
        rate_com: this.state.ratingComment,
      };
      const rating_score = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/mobile/rating",
        data,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const ratingJson = await rating_score.data;
      if (ratingJson == "rating insertion complete") {
        this.props.ModalRating(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <Modal
          className="modalRating"
          visible={this.props.isModalVisibleRating}
          onCancel={() => this.props.ModalRating(false)}
          closeIcon={
            <Link to="/dlt-Smartqueue/history">
              <img src={Close} />
            </Link>
          }
          footer={null}
        >
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modalRating-text-top"
            >
              <h1 className="text-modalRating-modal">
                {this.props.Modaldict[0]}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modalWarning-text-sup"
            >
              <h3 className="text-modalWarning-modal-detail">
                DLT Smart Queue Website
              </h3>
            </Col>
          </Row>
          {/* star */}
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modalRating-icon-star"
            >
              <Rate
                style={{ fontSize: 36 }}
                value={this.state.star}
                onChange={(value) => this.setState({ star: value })}
              />
            </Col>
          </Row>
          {/* text  */}
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              className="col-modalRating-text-title-comment"
            >
              <h4 className="text-title-comment-modalRating-modal">
                {this.props.Modaldict[1]}
              </h4>
            </Col>
          </Row>
          {/* input comment */}
          <Row className="row-modalRating-input-comment">
            <Col
              className="col-modalRating-input-comment"
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
            >
              <Input
                className={"input-modalRating-comment"}
                maxLength={50}
                onChange={(e) =>
                  this.setState({
                    ratingComment: e.target.value,
                  })
                }
              />
            </Col>
          </Row>

          {/* button */}
          <Row>
            <Col
              className="col-modalRating-button"
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 6, offset: 9 }}
              xl={{ span: 6, offset: 9 }}
            >
              {this.props.role === 1 ? (
                <Link to="/admin">
                  <Button
                    className="button-modalRating"
                    size="large"
                    onClick={() => this.submit()}
                  >
                    <p className="p-button-modalRating">
                      {this.props.Modaldict[2]}
                    </p>
                  </Button>
                </Link>
              ) : (
                <Link to="/dlt-Smartqueue/history">
                  <Button
                    className="button-modalRating"
                    size="large"
                    onClick={() => this.submit()}
                  >
                    <p className="p-button-modalRating">
                      {this.props.Modaldict[2]}
                    </p>
                  </Button>
                </Link>
              )}
              {/* <Link to="/history">
                <Button
                  className="button-modalRating"
                  onClick={() => this.submit()}
                >
                  <p className="p-button-modalRating">
                    {this.props.Modaldict[2]}
                  </p>
                </Button>
              </Link> */}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default ModalRating;
