import React from "react";
import { List } from "antd";
import axios from "axios";
import ReactLoading from "react-loading";
class VehicleType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const Car = await axios.get(
        process.env.REACT_APP_API_DLT1 + "/getVehicle",
        {
          mode: "cors",
          params: {
            language: this.props.language,
            ve_type: this.props.showType.Service.typeScreen + 1,
          },
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const CarJson = await Car.data;
      this.setState({
        data: CarJson,
        loading: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  Check_navigate(id, car) {
    if (
      this.props.showType.Service.Keyword === "ใหม่" ||
      this.props.showType.Service.Keyword === "ระหว่างประเทศ"
    ) {
      return this.props.next(
        2,
        {
          VE_type: {
            id: id,
            Car: car,
          },
        },
        this.props.language === 1 ? "ประเภทงาน" : "Select the type of work",
        this.props.language === 1
          ? "กรุณาเลือกประเภทงานที่ต้องการ"
          : "Select the type of work to receive the service."
      );
    } else {
      return this.props.next(
        1,
        {
          VE_type: {
            id: id,
            Car: car,
          },
        },
        this.props.language === 1
          ? "ข้อมูลใบอนุญาตขับขี่"
          : "Driving license information.",
        this.props.language === 1
          ? "กรุณากรอกข้อมูลต่อไปนี้"
          : "Please complete the following."
      );
    }
  }

  PersonalCarLicense() {
    return (
      <div>
        {this.state.loading === true ? (
          <List className="listItem_serviceType" itemLayout="horizontal">
            {this.state.data.map((item) => (
              <List.Item
                className="item"
                key={item.ve_id.toString()}
                onClick={() => this.Check_navigate(item.ve_id, item.ve_name)}
              >
                <p className="site">{item.ve_name}</p>
              </List.Item>
            ))}
          </List>
        ) : (
          <div className="size-loadding">
            <ReactLoading type="spinningBubbles" color={"#2f0465"} />
          </div>
        )}
      </div>
    );
  }

  PublicTransportLicense() {
    return (
      <div>
        {this.state.loading === true ? (
          <List className="listItem_serviceType" itemLayout="horizontal">
            {this.state.data.map((item) => (
              <List.Item
                className="item"
                key={item.ve_id.toString()}
                onClick={() => this.Check_navigate(item.ve_id, item.ve_name)}
              >
                <p className="site">{item.ve_name}</p>
              </List.Item>
            ))}
          </List>
        ) : (
          <div className="size-loadding">
            <ReactLoading type="spinningBubbles" color={"#2f0465"} />
          </div>
        )}
      </div>
    );
  }

  LicenseOfTheTransport() {
    return (
      <div>
        {this.state.loading === true ? (
          <List className="listItem" itemLayout="horizontal">
            {this.state.data.map((item) => (
              <List.Item
                className="item"
                key={item.ve_id.toString()}
                onClick={() => this.Check_navigate(item.ve_id, item.ve_name)}
              >
                <p className="site">{item.ve_name}</p>
              </List.Item>
            ))}
          </List>
        ) : (
          <div className="size-loadding">
            <ReactLoading type="spinningBubbles" color={"#2f0465"} />
          </div>
        )}
      </div>
      // <List className="listItem" itemLayout="horizontal">
      //   {this.state.data.map((item) => (
      //     <List.Item
      //       className="item"
      //       key={item.ve_id.toString()}
      //       onClick={() => this.Check_navigate(item.ve_id, item.ve_name)}
      //     >
      //       <p className="site">{item.ve_name}</p>
      //     </List.Item>
      //   ))}
      // </List>
    );
  }

  render() {
    const isShow = [
      {
        content: this.PersonalCarLicense(),
      },
      {
        content: this.PublicTransportLicense(),
      },
      {
        content: this.LicenseOfTheTransport(),
      },
    ];
    return (
      <div>
        <p className="title">{this.props.dict[0]}</p>
        <div className="container_office">
          {isShow[this.props.showType.Service.typeScreen].content}
        </div>
      </div>
    );
  }
}

export default VehicleType;
