import { Col, Row, Image, Divider } from "antd";
import React, { Component } from "react";
import axios from "axios";
import QRCode from "qrcode.react";
import LogoDLT from "../../img/logo/dlt.png";
import "./ticket.css";

export class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      per_id: "-",
      per_fname: "-",
      per_lname: "-",
      PROVINCE_NAME: "-",
      sit_name: "-",
      tyw_name: "-",
      boo_code: "-",
      boo_datebook: "-",
      boo_round: "-",
      tyw_comment: "-",
    };
  }

  componentDidMount() {
    try {
      axios
        .get(process.env.REACT_APP_API_DLT1 + "/getBookingInformation", {
          params: {
            boocode: this.props.boo_code,
          },
        })
        .then((response) => {
          if (response.data.lenght !== 0) {
            this.setState({
              per_id: response.data.per_id,
              per_fname: response.data.per_fname,
              per_lname: response.data.per_lname,
              PROVINCE_NAME: response.data.PROVINCE_NAME,
              sit_name: response.data.sit_name,
              tyw_name: response.data.tyw_name,
              boo_code: response.data.boo_code,
              boo_datebook: response.data.dateText,
              boo_round: response.data.boo_round,
              tyw_comment: response.data.tyw_comment,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    return (
      <div className="content-ticket">
        <Row justify="center" align="middle">
          <div className="page">
            <Col
              className="col-ticket"
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
            >
              <Row justify="center" align="top">
                <Col xs={20} sm={20} xl={20}>
                  <Row justify="center">
                    <Col xs={10} sm={5} xl={3}>
                      <Image
                        className="logodlt"
                        preview={false}
                        src={LogoDLT}
                      />
                    </Col>
                  </Row>
                  <Row justify="center">
                    <h2 className="title-ticket-queue">
                      ใบนัดอิเล็กทรอนิกส์(E-Booking)
                    </h2>
                  </Row>
                  {/* ข้อมูลส่วนตัว */}
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h3 className="text-top">ข้อมูลส่วนตัว</h3>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">หมายเลขประจำตัวประชาชน</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.id} */}
                      <h4 className="text-sub">{this.state.per_id}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">ชื่อ</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.fname} */}
                      <h4 className="text-sub">{this.state.per_fname}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">นามสกุล</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.lname} */}
                      <h4 className="text-sub">{this.state.per_lname}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />

                  {/* ข้อมูลการจอง */}
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h3 className="text-top">ข้อมูลการจอง</h3>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">จังหวัดที่ทำการจอง</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.data.PROVINCE_NAME} */}
                      <h4 className="text-sub">{this.state.PROVINCE_NAME}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">สำนักงานที่ทำการจอง</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.data.sit_name} */}
                      <h4 className="text-sub">{this.state.sit_name}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">ประเภทการจอง</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.data.tyw_name} */}
                      <h4 className="text-sub">{this.state.tyw_name}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />

                  {/* ข้อมูลการนัดหมาย */}
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h3 className="text-top">ข้อมูลการนัดหมาย</h3>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">รหัสการจอง</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.data.boo_code} */}
                      <h4 className="text-sub">{this.state.boo_code}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">วันที่นัด</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.data.boo_datebook_text} */}
                      <h4 className="text-sub">{this.state.boo_datebook}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly" />
                  <Row>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">รอบที่นัด</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* {this.props.data.boo_round} */}
                      <h4 className="text-sub">{this.state.boo_round}</h4>
                    </Col>
                  </Row>
                  <Divider className="dividerstly advice-print" />
                  <Row className="page-2">
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                    >
                      <h4 className="text-sub">คำแนะนำ</h4>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 15, offset: 3 }}
                      xl={{ span: 15, offset: 3 }}
                    >
                      {/* <div className="backN"> */}
                      {/* {this.props.data.tyw_comment} */}
                      <h4 className="text-sub">{this.state.tyw_comment}</h4>
                      {/* </div> */}
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col className="qrcode-ticket-admin">
                      <QRCode
                        className="QRcode-modal-queue-admin"
                        value={this.state.boo_code}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
      </div>
    );
  }
}

export default Ticket;
