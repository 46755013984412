import React from "react";
import "../../../../css/Home/ConfirmQuqu.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Button, Col, Row } from "antd";
import axios from "axios";
import ModalWarning from "../../Components/Modal/ModalWarning.component";
import ModalSameQ from "../../Components/Modal/ModalSameQ.component";
import "../Modal/css/ModalWarning.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

class ConfirmQueue extends React.Component {
  constructor(props) {
    super(props);
    this.onChangecheckBoxWarning = this.onChangecheckBoxWarning.bind(this);
    this.ModalWarning = this.ModalWarning.bind(this);
    this.onChangecheckBoxSameQ = this.onChangecheckBoxSameQ.bind(this);
    this.ModalSameQ = this.ModalSameQ.bind(this);
    this.ModalSuccess = this.ModalSuccess.bind(this);
    this.setDataRebook = this.setDataRebook.bind(this);
    this.setDataSuccess = this.setDataSuccess.bind(this);
    this.swal = this.swal.bind(this);
    this.state = {
      user: [],
      isModalVisibleWarning: false,
      checkBoxWarning: false,
      checkBoxSameQ: false,
      isModalVisibleSameQ: false,
      isModalVisibleSuccess: false,
      dataRebook: [],
      date: null,
      boo_code: null,
      Tyw_comment: null,
      ticketurl: null,
      texterror: "",
    };
  }

  setDataSuccess(boo_code, comment, ticketurl) {
    this.setState({
      boo_code: boo_code,
      Tyw_comment: comment,
      ticketurl: ticketurl,
    });
  }

  setDataRebook(data, date) {
    this.setState({
      dataRebook: data,
      date: date,
    });
  }

  onChangecheckBoxWarning(e) {
    this.setState({
      checkBoxWarning: e.target.checked,
    });
  }
  ModalWarning(type) {
    this.setState({
      isModalVisibleWarning: type,
    });
  }

  onChangecheckBoxSameQ(e) {
    this.setState({
      checkBoxSameQ: e.target.checked,
    });
  }

  ModalSameQ(type) {
    this.setState({
      isModalVisibleSameQ: type,
    });
  }

  ModalSuccess(type) {
    this.setState({
      isModalVisibleSuccess: type,
    });
  }

  async componentDidMount() {
    try {
      const data_profile = {
        username: this.props.username,
      };
      const profile = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/getPersonalProfile",
        data_profile,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const ProfileJson = await profile.data;
      this.setState({
        user: ProfileJson,
      });
    } catch (error) {
      console.log(error);
    }
  }

  swal() {
    const MySwal = withReactContent(Swal);
    let timerInterval;
    MySwal.fire({
      allowOutsideClick: false,
      title: this.props.dict[12],
      icon: "success",
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        MySwal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === MySwal.DismissReason.timer) {
        this.props.next(
          1,
          {
            ConfirmQueue: {
              user: this.state.user,
              boo_code: this.state.boo_code,
              Tyw_comment: this.state.Tyw_comment,
              ticketurl: this.state.ticketurl,
            },
          },
          this.props.language === 1
            ? "ข้อมูลการจองคิว"
            : "Booking information.",
          this.props.language === 1
            ? "การจองคิวเสร็จสิ้น"
            : "Successful booking."
        );
      }
    });
  }

  swal_Error = () => {
    const MySwal = withReactContent(Swal);
    let timerInterval;
    MySwal.fire({
      icon: "error",
      text: this.state.texterror,
      // confirmButtonColor: "#dc3741",
      timer: 10000,
      didOpen: () => {
        MySwal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then(() => {
      this.props.prev();
    });
  };

  setTextError = (text) => {
    this.setState({
      texterror: text,
    });
  };

  convertDateTime = (time, time_unit) => {
    // เวลาไทย
    let time_thai = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    let text_time = "";
    let new_time = parseInt(time.substring(0, 2), 10);
    if (new_time >= 12) {
      const isLargeNumber = (element) => element === new_time;
      text_time = time_thai.findIndex(isLargeNumber) + ":" + time_unit + " PM";
    } else {
      text_time = time.substring(0, 2) + ":" + time_unit + " AM";
    }
    return text_time;
  };

  render() {
    const percentage =
      this.props.data.Calendar.count === "เต็ม"
        ? 0
        : (this.props.data.Calendar.count / this.props.data.Calendar.Maxcount) *
          100;
    return (
      <div className="container-cf-Q">
        <h2 className="title">
          {this.props.language === 1 ? "ยืนยันการจองคิว" : "Confirm booking"}
        </h2>
        <Row justify="center" className="contain_cf">
          <Col xl={{ span: 24 }} xs={{ span: 20 }} sm={{ span: 24 }}>
            <Row justify="center">
              <Col xl={4} xs={10} sm={4}>
                <div className="size">
                  <CircularProgressbarWithChildren
                    className="CircularProgressbar-inverted"
                    background
                    backgroundPadding={5}
                    strokeWidth={6}
                    value={percentage}
                    classes={{
                      root: "CircularProgressbar",
                      trail: "CircularProgressbar-trail",
                      path: "CircularProgressbar-path",
                      text: "CircularProgressbar-text some-additional-class",
                      background: "CircularProgressbar-background",
                    }}
                    styles={{
                      background: {
                        fill: "#7237FF",
                      },
                    }}
                  >
                    <div className="text_cicle">
                      <strong>{this.props.dict[2]}</strong>
                    </div>
                    <div className="text_cicle" style={{ marginTop: "2%" }}>
                      <strong>{this.props.data.Calendar.count}</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="footer-text-cicle">
                  <div className="time_round-one">
                    <p>
                      {this.props.language === 1
                        ? this.props.data.Calendar.round.substring(0, 5)
                        : this.convertDateTime(
                            this.props.data.Calendar.round.substring(0, 5),
                            this.props.data.Calendar.round
                              .substring(0, 5)
                              .substring(3, 5)
                          )}
                    </p>
                    {/* <p>{this.props.dict[1]}</p> */}
                  </div>
                  <div className="time_round-two">
                    {/* <p>{this.props.data.Calendar.round.substring(0, 5)}</p> */}
                    <p>{this.props.dict[1]}</p>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 12, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
              >
                <Row justify="space-between">
                  <Col className="data_title" xl={10} xs={10} sm={10}>
                    <p>{this.props.dict[5]}:</p>
                  </Col>
                  <Col className="data_detail" xl={12} xs={12} sm={12}>
                    <p>{this.state.user.per_id}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col className="data_title" xl={10} xs={10} sm={10}>
                    <p>{this.props.dict[4]}:</p>
                  </Col>
                  <Col className="data_detail" xl={12} xs={12} sm={12}>
                    <p>
                      {this.state.user.per_fname} &nbsp;
                      {this.state.user.per_lname}
                    </p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col className="data_title" xl={10} xs={10} sm={10}>
                    <p>{this.props.dict[7]}:</p>
                  </Col>
                  <Col className="data_detail" xl={12} xs={12} sm={12}>
                    <p>{this.props.data.Calendar.formatdate}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col className="data_title" xl={10} xs={10} sm={10}>
                    <p>{this.props.dict[9]}:</p>
                  </Col>
                  <Col className="data_detail" xl={12} xs={12} sm={12}>
                    <p>
                      {this.props.language === 1
                        ? this.props.data.Calendar.round
                        : this.convertDateTime(
                            this.props.data.Calendar.round.substring(0, 5),
                            this.props.data.Calendar.round
                              .substring(0, 5)
                              .substring(3, 5)
                          ) +
                          " - " +
                          this.convertDateTime(
                            this.props.data.Calendar.round.substring(8, 13),
                            this.props.data.Calendar.round
                              .substring(8, 13)
                              .substring(3, 5)
                          )}
                    </p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col className="data_title" xl={10} xs={10} sm={10}>
                    <p>{this.props.dict[8]}:</p>
                  </Col>
                  <Col className="data_detail" xl={12} xs={12} sm={12}>
                    <p>{this.props.data.Typework.tyw_name}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col className="data_title" xl={10} xs={10} sm={10}>
                    <p>{this.props.dict[6]}:</p>
                  </Col>
                  <Col className="data_detail" xl={12} xs={12} sm={12}>
                    <p>{this.props.data.site.site_name}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.props.current === 8 && (
          <div className="steps-action-cf" style={{ paddingTop: "2%" }}>
            <Button
              className="back-btn"
              size="large"
              style={{ margin: "0 8px" }}
              onClick={() => this.props.prev()}
            >
              <span>{this.props.dict[11]}</span>
            </Button>
            <Button
              className="back-btn"
              size="large"
              onClick={() => this.ModalWarning(true)}
            >
              <span>{this.props.dict[10]}</span>
            </Button>
          </div>
        )}
        <ModalWarning
          isModalVisibleWarning={this.state.isModalVisibleWarning}
          ModalWarning={this.ModalWarning}
          onChangecheckBoxWarning={this.onChangecheckBoxWarning}
          checkBoxWarning={this.state.checkBoxWarning}
          data={this.props.data}
          ModalSameQ={this.ModalSameQ}
          setDataRebook={this.setDataRebook}
          setDataSuccess={this.setDataSuccess}
          ModalSuccess={this.ModalSuccess}
          swal={this.swal}
          swal_Error={this.swal_Error}
          Modaldict={this.props.Modaldict}
          username={this.props.username}
          language={this.props.language}
          username_Admin={this.props.username_Admin}
          setTextError={this.setTextError}
        />
        <ModalSameQ
          isModalVisibleSameQ={this.state.isModalVisibleSameQ}
          ModalSameQ={this.ModalSameQ}
          onChangecheckBoxSameQ={this.onChangecheckBoxSameQ}
          checkBoxSameQ={this.state.checkBoxSameQ}
          dataRebook={this.state.dataRebook}
          date={this.state.date}
          data={this.props.data}
          setDataSuccess={this.setDataSuccess}
          ModalSuccess={this.ModalSuccess}
          swal={this.swal}
          username={this.props.username}
          Modaldict={this.props.Modaldict_2}
          language={this.props.language}
          swal_Error={this.swal_Error}
          username_Admin={this.props.username_Admin}
          setTextError={this.setTextError}
        />
      </div>
    );
  }
}

export default ConfirmQueue;
