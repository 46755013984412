import React from "react";
import { Grid, Steps } from "antd";

const { useBreakpoint } = Grid;
const { Step } = Steps;

const StepHome = (props) => {
  const { md } = useBreakpoint();
  return (
    <Steps
      current={props.step_current}
      direction={md ? "vertical" : "horizontal"}
      // direction="vertical"
      className="step-home"
    >
      {props.step.map((item) => (
        <Step
          key={item.id.toString()}
          className="step-nav-home"
          title={item.title}
          description={item.description}
        />
      ))}
    </Steps>
  );
};

export default StepHome;
