/* eslint-disable */
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LeftMenu from "./LeftMenu";
import { Drawer, Button } from "antd";
import { withRouter } from "react-router";
import Logo from "../../../../Icon/logo-web.svg";
import Admin from "../../Admin.screen";
import Home from "../../Home/Home.screen";
import CryptoJS from "crypto-js";

class NavbarAdmin extends Component {
  constructor(props) {
    super(props);
    this.callbackUser = this.callbackUser.bind(this);
    this.state = {
      visible: false,
      newUser: null,
      id_Card: null,
      language: CryptoJS.AES.decrypt(
        localStorage.getItem("lang"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      role: CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      username: localStorage.getItem("username"),
      username_Admin: localStorage.getItem("username"),
    };
  }

  componentDidMount() {
    this.setState({
      language: CryptoJS.AES.decrypt(
        localStorage.getItem("lang"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      role: CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      username: localStorage.getItem("username"),
    });
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  callbackUser(user, id, language) {
    this.setState({
      username: user,
      id_Card: id,
      language: language,
    });
  }

  render() {
    const HeaderWithRouter = withRouter(LeftMenu);
    return (
      <div className="container-main-page">
        <nav className="menuBar">
          <div className="logo">
            <img className="logo-nav" src={Logo} />
            <span>| กรมการขนส่งทางบก </span>
          </div>
          {/* <div className="title-header-nav">
            <span>| กรมการขนส่งทางบก </span>
          </div> */}
          <div className="menuCon">
            <div className="leftMenu">
              <HeaderWithRouter language={this.state.language} />
            </div>
            <Button
              className="barsMenu"
              type="primary"
              onClick={this.showDrawer}
            >
              <span className="barsBtn"></span>
            </Button>
            <Drawer
              // title="Basic Drawer"
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <HeaderWithRouter language={this.state.language} />
            </Drawer>
          </div>
        </nav>
        <Switch>
          <Route exact path={this.props.match.path}>
            <Admin
              language={this.state.language}
              username={this.state.username}
              username_Admin={this.state.username_Admin}
              callbackUser={this.callbackUser}
            />
          </Route>
          <Route path={this.props.match.path + "/home"}>
            <Home
              language={parseInt(this.state.language)}
              username={this.state.username}
              role={parseInt(this.state.role)}
              id={this.state.id_Card}
              username_Admin={this.state.username_Admin}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(NavbarAdmin);
