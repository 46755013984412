import React from "react";
import { List } from "antd";

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkEmptyDaqta = (index) => {
    let result;
    if (this.props.language === 2) {
      result =
        this.props.data.ValueCheckEmpty.data[2].filter[index].gotwork === false
          ? true
          : false;
    } else {
      result =
        this.props.data.EmptyFillter.data[index].gotwork === false
          ? true
          : false;
    }
    return result;
  };

  PersonalCarLicense() {
    // ใบอนุฐาตส่วนบุคคล
    const car_type = 0;
    return (
      <List className="listItem_serviceType" itemLayout="horizontal">
        <List.Item
          className={
            // this.props.data.EmptyFillter.data[0].gotwork === false
            this.checkEmptyDaqta(0) ? "disable-item" : "item"
          }
          onClick={() =>
            // this.props.data.EmptyFillter.data[0].gotwork === false
            this.checkEmptyDaqta(0)
              ? null
              : [
                  this.props.next(
                    1,
                    {
                      Service: {
                        Keyword:
                          this.props.language === 1 ? "ใหม่" : " NEW THAI",
                        typeScreen: car_type,
                      },
                      Info: [
                        {
                          date: null,
                        },
                      ],
                    },
                    this.props.language === 1
                      ? "ประเภทยานพาหนะ"
                      : "Vehicle type",
                    this.props.language === 1
                      ? "เลือกประเภทยานพาหนะ"
                      : "Select vehicle type."
                  ),
                ]
          }
        >
          <p className="site">{this.props.dict[1]}</p>
        </List.Item>
        <List.Item
          className={
            // this.props.data.EmptyFillter.data[1].gotwork === false
            this.checkEmptyDaqta(1) ? "disable-item" : "item"
          }
          onClick={() =>
            // this.props.data.EmptyFillter.data[1].gotwork === false
            this.checkEmptyDaqta(1)
              ? null
              : [
                  this.props.next(
                    1,
                    {
                      Service: {
                        Keyword:
                          this.props.language === 1 ? "ต่ออายุ" : " RENEW THAI",
                        typeScreen: car_type,
                      },
                    },
                    this.props.language === 1
                      ? "ประเภทยานพาหนะ"
                      : "Vehicle type",
                    this.props.language === 1
                      ? "เลือกประเภทยานพาหนะ"
                      : "Select vehicle type."
                  ),
                ]
          }
        >
          <p className="site">{this.props.dict[2]}</p>
        </List.Item>
        {this.props.language === 1 ? (
          <div>
            <List.Item
              className={
                this.props.data.EmptyFillter.data[2].gotwork === false
                  ? "disable-item"
                  : "item"
              }
              onClick={() =>
                this.props.data.EmptyFillter.data[2].gotwork === false
                  ? null
                  : [
                      this.props.next(
                        1,
                        {
                          Service: {
                            Keyword: "รับรอง",
                            typeScreen: car_type,
                          },
                        },
                        this.props.language === 1
                          ? "ประเภทยานพาหนะ"
                          : "Vehicle type",
                        this.props.language === 1
                          ? "เลือกประเภทยานพาหนะ"
                          : "Select vehicle type."
                      ),
                    ]
              }
            >
              <p className="site">{this.props.dict[3]}</p>
            </List.Item>
            <List.Item
              className={
                this.props.data.EmptyFillter.data[0].gotwork === false
                  ? "disable-item"
                  : "item"
              }
              onClick={() =>
                this.props.data.EmptyFillter.data[0].gotwork === false
                  ? null
                  : [
                      this.props.next(
                        1,
                        {
                          Service: {
                            Keyword: "ระหว่างประเทศ",
                            typeScreen: car_type,
                          },
                          Info: [
                            {
                              date: null,
                            },
                          ],
                        },
                        this.props.language === 1
                          ? "ประเภทยานพาหนะ"
                          : "Vehicle type",
                        this.props.language === 1
                          ? "เลือกประเภทยานพาหนะ"
                          : "Select vehicle type."
                      ),
                    ]
              }
            >
              <p className="site">{this.props.dict[4]}</p>
            </List.Item>
            <List.Item
              className={
                this.props.data.EmptyFillter.data[4].gotwork === false
                  ? "disable-item"
                  : "item"
              }
              onClick={() =>
                this.props.data.EmptyFillter.data[4].gotwork === false
                  ? null
                  : [
                      this.props.next(
                        1,
                        {
                          Service: {
                            Keyword: "อื่น",
                            typeScreen: car_type,
                          },
                        },
                        this.props.language === 1
                          ? "ประเภทยานพาหนะ"
                          : "Vehicle type",
                        this.props.language === 1
                          ? "เลือกประเภทยานพาหนะ"
                          : "Select vehicle type."
                      ),
                    ]
              }
            >
              <p className="site">{this.props.dict[5]}</p>
            </List.Item>
          </div>
        ) : null}
      </List>
    );
  }

  PublicTransportLicense() {
    // ใบอนุญาตรถสาธารณะ
    const car_type = 1;
    return (
      <List className="listItem_serviceType" itemLayout="horizontal">
        <List.Item
          className={
            this.props.data.EmptyFillter.data[0].gotwork === false
              ? "disable-item"
              : "item"
          }
          onClick={() =>
            this.props.data.EmptyFillter.data[0].gotwork === false
              ? null
              : [
                  this.props.next(
                    1,
                    {
                      Service: {
                        Keyword: "ใหม่",
                        typeScreen: car_type,
                      },
                      Info: [
                        {
                          date: null,
                        },
                      ],
                    },
                    this.props.language === 1
                      ? "ประเภทยานพาหนะ"
                      : "Vehicle type",
                    this.props.language === 1
                      ? "เลือกประเภทยานพาหนะ"
                      : "Select vehicle type."
                  ),
                ]
          }
        >
          <p className="site">{this.props.dict[1]}</p>
        </List.Item>
        <List.Item
          className={
            this.props.data.EmptyFillter.data[1].gotwork === false
              ? "disable-item"
              : "item"
          }
          onClick={() =>
            this.props.data.EmptyFillter.data[1].gotwork === false
              ? null
              : [
                  this.props.next(
                    1,
                    {
                      Service: {
                        Keyword: "ต่ออายุ",
                        typeScreen: car_type,
                      },
                    },
                    this.props.language === 1
                      ? "ประเภทยานพาหนะ"
                      : "Vehicle type",
                    this.props.language === 1
                      ? "เลือกประเภทยานพาหนะ"
                      : "Select vehicle type."
                  ),
                ]
          }
        >
          <p className="site">{this.props.dict[2]}</p>
        </List.Item>
        <List.Item
          className={
            this.props.data.EmptyFillter.data[2].gotwork === false
              ? "disable-item"
              : "item"
          }
          onClick={() =>
            this.props.data.EmptyFillter.data[2].gotwork === false
              ? null
              : [
                  this.props.next(
                    1,
                    {
                      Service: {
                        Keyword: "อื่น",
                        typeScreen: car_type,
                      },
                    },
                    this.props.language === 1
                      ? "ประเภทยานพาหนะ"
                      : "Vehicle type",
                    this.props.language === 1
                      ? "เลือกประเภทยานพาหนะ"
                      : "Select vehicle type."
                  ),
                ]
          }
        >
          <p className="site">{this.props.dict[5]}</p>
        </List.Item>
      </List>
    );
  }

  LicenseOfTheTransport() {
    // ใบอนุญาต พรบ. ขนส่ง
    const car_type = 2;
    return (
      <div className="container_office">
        <List className="listItem_serviceType" itemLayout="horizontal">
          <List.Item
            className={
              this.props.data.EmptyFillter.data[0].gotwork === false
                ? "disable-item"
                : "item"
            }
            onClick={() =>
              this.props.data.EmptyFillter.data[0].gotwork === false
                ? null
                : [
                    this.props.next(
                      1,
                      {
                        Service: {
                          Keyword: "ใหม่",
                          typeScreen: car_type,
                        },
                        Info: [
                          {
                            date: null,
                          },
                        ],
                      },
                      this.props.language === 1
                        ? "ประเภทยานพาหนะ"
                        : "Vehicle type",
                      this.props.language === 1
                        ? "เลือกประเภทยานพาหนะ"
                        : "Select vehicle type."
                    ),
                  ]
            }
          >
            <p className="site">{this.props.dict[1]}</p>
          </List.Item>
          <List.Item
            className={
              this.props.data.EmptyFillter.data[1].gotwork === false
                ? "disable-item"
                : "item"
            }
            onClick={() =>
              this.props.data.EmptyFillter.data[1].gotwork === false
                ? null
                : [
                    this.props.next(
                      1,
                      {
                        Service: {
                          Keyword: "ต่ออายุ",
                          typeScreen: car_type,
                        },
                        // Info: {
                        //   date: null,
                        // },
                      },
                      this.props.language === 1
                        ? "ประเภทยานพาหนะ"
                        : "Vehicle type",
                      this.props.language === 1
                        ? "เลือกประเภทยานพาหนะ"
                        : "Select vehicle type."
                    ),
                  ]
            }
          >
            <p className="site">{this.props.dict[2]}</p>
          </List.Item>
          <List.Item
            className={
              this.props.data.EmptyFillter.data[2].gotwork === false
                ? "disable-item"
                : "item"
            }
            onClick={() =>
              this.props.data.EmptyFillter.data[2].gotwork === false
                ? null
                : [
                    this.props.next(
                      1,
                      {
                        Service: {
                          Keyword: "อื่น",
                          typeScreen: car_type,
                        },
                      },
                      this.props.language === 1
                        ? "ประเภทยานพาหนะ"
                        : "Vehicle type",
                      this.props.language === 1
                        ? "เลือกประเภทยานพาหนะ"
                        : "Select vehicle type."
                    ),
                  ]
            }
          >
            <p className="site">{this.props.dict[5]}</p>
          </List.Item>
        </List>
      </div>
    );
  }

  render() {
    const isShow = [
      {
        content: this.PersonalCarLicense(),
      },
      {
        content: this.PublicTransportLicense(),
      },
      {
        content: this.LicenseOfTheTransport(),
      },
    ];
    return (
      <div>
        <p className="title">{this.props.dict[0]}</p>
        <div className="container_office">
          {isShow[this.props.showType.group_id.typeScreen].content}
        </div>
      </div>
    );
  }
}

export default Service;
