/* eslint-disable */
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import { Drawer, Button } from "antd";
import Home from "../../Home/Home.screen";
import History from "../../History/History.screen";
import Profile from "../../Profile/Profile.screen";
import { withRouter } from "react-router";
import Logo from "../../../../Icon/logo-web.svg";
import ProfileChange from "../../Profile/profilechange.screen";
import DocumentDetail from "../../History/DocumentDetail";
import ChangePassword from "../../Profile/changePassword";
import HistoryCancel from "../../History/HistoryCancel.screen";
import CryptoJS from "crypto-js";
import WebMenu from "./WebMenu";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: CryptoJS.AES.decrypt(
        localStorage.getItem("lang"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      role: CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      username: localStorage.getItem("username"),
      // language: parseInt(localStorage.getItem("lang")),
    };
  }

  componentDidMount() {
    this.setState({
      language: CryptoJS.AES.decrypt(
        localStorage.getItem("lang"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      role: CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8),
      username: localStorage.getItem("username"),
    });
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const HeaderWithRouter = withRouter(LeftMenu);
    const webMenu = withRouter(WebMenu);
    return (
      <div className="container-main-page">
        <nav className="menuBar">
          <div className="logo">
            <img className="logo-nav" src={Logo} />
            <span>| กรมการขนส่งทางบก </span>
          </div>
          <div className="menuCon">
            <div className="leftMenu">
              <WebMenu language={this.state.language} />
            </div>
            <Button
              className="barsMenu"
              type="primary"
              onClick={this.showDrawer}
            >
              <span className="barsBtn"></span>
            </Button>
            <Drawer
              // title="Basic Drawer"
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <HeaderWithRouter
                closeNav={this.onClose}
                language={this.state.language}
              />
            </Drawer>
          </div>
        </nav>
        <main>
          <Switch>
            <Route exact path={this.props.match.path}>
              <Home
                language={parseInt(this.state.language)}
                username={this.state.username}
                role={parseInt(this.state.role)}
              />
            </Route>
            <Route path={this.props.match.path + "/profile"}>
              <Profile
                language={this.state.language}
                username={this.state.username}
              />
            </Route>
            <Route path={this.props.match.path + "/history"}>
              <History
                language={this.state.language}
                username={this.state.username}
              />
            </Route>
            <Route path={this.props.match.path + "/profilechange"}>
              <ProfileChange
                language={this.state.language}
                username={this.state.username}
              />
            </Route>
            <Route path={this.props.match.path + "/changepassword"}>
              <ChangePassword
                language={this.state.language}
                username={this.state.username}
              />
            </Route>
            <Route
              path={this.props.match.path + "/documentdetail"}
              component={DocumentDetail}
            />
            <Route path={this.props.match.path + "/historycancel"}>
              <HistoryCancel
                language={this.state.language}
                username={this.state.username}
              />
            </Route>
          </Switch>
        </main>
      </div>
    );
  }
}

export default withRouter(Navbar);
