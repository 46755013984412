import React from "react";
import { Modal, Button, Tag, Row, Col } from "antd";
import "./ModalQueue.css";
import Ticket from "../../Ticket/Ticket.component";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";

class DetailQueuePrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      timestamp: this.props.data.boo_datetime,
      boo_code: this.props.data.boo_code,
      // loading: false,
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  printTicket() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button key="submit" type="primary">
              พิมพ์
            </Button>
          )}
          content={() => this.componentRef}
        />
        <div style={{ display: "none" }}>
          <Ticket
            data={this.props.data}
            boo_code={this.props.data.boo_code}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Tag className="pointor-tag" color={"green"} onClick={this.showModal}>
          รอดำเนินการ
        </Tag>
        <Modal
          className="print"
          visible={this.state.visible}
          title="ข้อมูลการจองคิว"
          onCancel={this.handleCancel}
          footer={[this.printTicket()]}
        >
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              รหัสการจอง
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.boo_code}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              วันที่จอง
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.boo_datebook}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              รอบที่จอง
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.boo_round}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              ประเภทงาน
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.tyw_name}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              สถานที่รับบริการ
            </Col>
            <Col
              xl={{ span: 14, offset: 2 }}
              sm={{ span: 14 }}
              xs={{ span: 14 }}
            >
              {this.props.data.sit_name}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
export default DetailQueuePrint;
