/* eslint-disable */
import React from "react";
import { List, Button, Row, Col } from "antd";
import SiteModal from "../Modal/SiteModal.component";
import ReactLoading from "react-loading";
import axios from "axios";

class ServiceType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkEmpty: [],
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      let data = {
        sit_id: this.props.data.site.site_id,
      };
      const check_empty = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/checkEmptyWork",
        data,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const emptyJson = await check_empty.data;
      this.setState(
        {
          checkEmpty: emptyJson,
        },
        () => {
          this.setState({ loading: true });
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="office-content">
        <p className="title">{this.props.dict[0]}</p>
        {this.state.loading === false ? (
          <div className="loading-center-servicetype">
            <ReactLoading type="spinningBubbles" color={"#2f0465"} />
          </div>
        ) : (
          <div className="container_office">
            <List className="listItem_serviceType" itemLayout="horizontal">
              {/* ประเภทงาน */}
              {this.props.language === 1 ? (
                <List.Item
                  className={
                    this.state.checkEmpty[0].gotwork === false
                      ? "disable-item"
                      : "item"
                  }
                  onClick={() =>
                    this.state.checkEmpty[0].gotwork === false
                      ? null
                      : [
                          this.props.next(
                            5,
                            {
                              group_id: {
                                group_id: 1,
                                group_name: "งานทะเบียน",
                              },
                              Service: {
                                Keyword: null,
                              },
                              Info: [
                                {
                                  date: null,
                                },
                              ],
                              VE_type: {
                                id: null,
                                Car: null,
                              },
                            },
                            this.props.language === 1
                              ? "ประเภทงาน"
                              : "Select the type of work",
                            this.props.language === 1
                              ? "กรุณาเลือกประเภทงานที่ต้องการ"
                              : "Select the type of work to receive the service."
                          ),
                        ]
                  }
                >
                  <p className="site">{this.props.dict[1]}</p>
                </List.Item>
              ) : null}
              {/* ประเภทใบอนุญาตขับรถ */}
              <List.Item
                className={"item"}
                onClick={() => [
                  this.props.next(
                    1,
                    {
                      group_id: {
                        group_id: null,
                      },
                      ValueCheckEmpty: {
                        data: this.state.checkEmpty,
                      },
                    },
                    this.props.language === 1
                      ? "ประเภทใบอนุญาตขับรถ"
                      : "Driver's license type.",
                    this.props.language === 1
                      ? "เลือกประเภทใบอนุญาตขับรถ"
                      : "Select type of driving license."
                  ),
                ]}
              >
                <p className="site">{this.props.dict[2]}</p>
              </List.Item>
              {/* งานตรวจสภาพ */}
              {this.props.language === 1 ? (
                <List.Item
                  className={
                    this.state.checkEmpty[1].gotwork === false
                      ? "disable-item"
                      : "item"
                  }
                  onClick={() =>
                    this.state.checkEmpty[1].gotwork === false
                      ? null
                      : [
                          this.props.next(
                            5,
                            {
                              group_id: {
                                group_id: 3,
                                group_name: "งานตรวจสภาพ",
                              },
                              Service: {
                                Keyword: null,
                              },
                              Info: [
                                {
                                  date: null,
                                },
                              ],
                              VE_type: {
                                id: null,
                                Car: null,
                              },
                            },
                            this.props.language === 1
                              ? "ประเภทงาน"
                              : "Select the type of work",
                            this.props.language === 1
                              ? "กรุณาเลือกประเภทงานที่ต้องการ"
                              : "Select the type of work to receive the service."
                          ),
                        ]
                  }
                >
                  <p className="site">{this.props.dict[3]}</p>
                </List.Item>
              ) : null}
              {/* บริการด้านอื่นๆ */}
              {this.props.language === 1 ? (
                <List.Item
                  className={
                    this.state.checkEmpty[6].gotwork === false
                      ? "disable-item"
                      : "item"
                  }
                  onClick={() =>
                    this.state.checkEmpty[6].gotwork === false
                      ? null
                      : [
                          this.props.next(
                            5,
                            {
                              group_id: {
                                group_id: 8,
                                group_name: "บริการด้านอื่นๆ",
                              },
                              Service: {
                                Keyword: null,
                              },
                              Info: [
                                {
                                  date: null,
                                },
                              ],
                              VE_type: {
                                id: null,
                                Car: null,
                              },
                            },
                            this.props.language === 1
                              ? "ประเภทงาน"
                              : "Select the type of work",
                            this.props.language === 1
                              ? "กรุณาเลือกประเภทงานที่ต้องการ"
                              : "Select the type of work to receive the service."
                          ),
                        ]
                  }
                >
                  <p className="site">
                    {this.props.language === 1
                      ? "บริการด้านอื่นๆ"
                      : "Other Services"}
                  </p>
                </List.Item>
              ) : null}
              {/* บริการด้านอื่นๆ */}
              {this.props.language === 1 ? (
                <List.Item
                  className={
                    this.state.checkEmpty[7].gotwork === false
                      ? "disable-item"
                      : "item"
                  }
                  onClick={() =>
                    this.state.checkEmpty[7].gotwork === false
                      ? null
                      : [
                          this.props.next(
                            5,
                            {
                              group_id: {
                                group_id: 9,
                                group_name: "อบรมคืนคะแนน",
                              },
                              Service: {
                                Keyword: null,
                              },
                              Info: [
                                {
                                  date: null,
                                },
                              ],
                              VE_type: {
                                id: null,
                                Car: null,
                              },
                            },
                            this.props.language === 1
                              ? "ประเภทงาน"
                              : "Select the type of work",
                            this.props.language === 1
                              ? "กรุณาเลือกประเภทงานที่ต้องการ"
                              : "Select the type of work to receive the service."
                          ),
                        ]
                  }
                >
                  <p className="site">
                    {this.props.language === 1
                      ? "อบรมคืนคะแนน"
                      : "Demerit Ponts System Retraining"}
                  </p>
                </List.Item>
              ) : null}
            </List>
          </div>
        )}

        <SiteModal
          language={this.props.language}
          sit_id={this.props.data.site.site_id}
        />
      </div>
    );
  }
}

export default ServiceType;
