import React from "react";
import { Row, Col, Button } from "antd";
import "../../../../css/Home/DocumentDetail.css";
import ModalRating from "../Modal/ModalRating.component";

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.onChangecheckBoxRating = this.onChangecheckBoxRating.bind(this);
    this.ModalRating = this.ModalRating.bind(this);
    this.state = {
      data: [],
      isModalVisibleRating: false,
      checkBoxRating: false,
    };
  }

  onChangecheckBoxRating(e) {
    this.setState({
      checkBoxRating: e.target.checked,
    });
  }
  ModalRating(type) {
    this.setState({
      isModalVisibleRating: type,
    });
  }

  render() {
    return (
      <div>
        <Row className="row-header-documentDetail">
          {/* header */}
          <Col
            className="col-header-documentDetail"
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <Col offset={1}>
              <h3 className="title-header-documentDetail">
                {this.props.dict[1]}
              </h3>
            </Col>
          </Col>
          {/* content */}
          <Col
            className="col-content-documentDetail"
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <Row>
              {/* detail */}
              <Col
                className="col-content-documentDetail-detail"
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
              >
                {/* document and office */}
                <Row>
                  <Col
                    xs={{ span: 20 }}
                    sm={{ span: 11 }}
                    xl={{ span: 11 }}
                    className="col-content-documentDetail-detail-name"
                  >
                    <p className="p-topic-doucumentDrtail">
                      {this.props.language === 1
                        ? "เอกสารที่ใช้"
                        : "Required Documents"}{" "}
                      {this.props.data.group_id.group_name}
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 20 }}
                    sm={{ span: 11 }}
                    xl={{ span: 11 }}
                    className="col-content-documentDetail-detail-passport"
                  >
                    <p className="p-topic-doucumentDrtail">
                      {this.props.data.site.site_name}
                    </p>
                  </Col>
                </Row>
                {/* tyw*/}
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    xl={{ span: 24 }}
                    className="col-content-documentDetail-detail-date"
                  >
                    <p className="p-topic-doucumentDrtail">
                      {this.props.dict[2]} : {this.props.data.Typework.tyw_name}
                    </p>
                  </Col>
                </Row>
                {/* tyw */}
                <Row>
                  <Col
                    xs={{ span: 20 }}
                    sm={{ span: 22 }}
                    xl={{ span: 22 }}
                    className="col-content-documentDetail-detail-comment"
                  >
                    {/* <Row> */}
                    <p className="p-topic-doucumentDrtail">
                      {this.props.data.ConfirmQueue.Tyw_comment}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="steps-action position-btn">
          {this.props.current === 10 && (
            <Button
              className="back-btn"
              type="primary"
              size="large"
              onClick={() => this.ModalRating(true)}
            >
              {this.props.dict[13]}
            </Button>
          )}
        </div>
        <ModalRating
          isModalVisibleRating={this.state.isModalVisibleRating}
          ModalRating={this.ModalRating}
          onChangecheckBoxRating={this.onChangecheckBoxRating}
          data={this.props.data}
          Modaldict={this.props.Modaldict}
          username={this.props.username}
          role={this.props.role}
        />
      </div>
    );
  }
}

export default Detail;
