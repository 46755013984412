/* eslint-disable */
import React from "react";
import { Row, Col } from "antd";
import "./css/DocumentDetail.css";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
class DocumentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 0
    ) {
      return <Redirect to="/main" />;
    }
    const { language, sit_name, tyg_name, tyw_name, tyw_comment } =
      this.props.location.state;
    return (
      <div className="content-documentDetail">
        <Row justify="center" className="bg-row-documentDetail">
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            xl={{ span: 18 }}
            className="col-bg-documentDetail"
          >
            <Row justify="center" className="row-header-documentDetail">
              {/* header */}
              <Col
                className="col-header-documentDetail"
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                xl={{ span: 20 }}
              >
                <Col offset={1}>
                  <h2 className="title-header-documentDetail">
                    {language == 1
                      ? "รายละเอียดเอกสารที่ต้องเตรียม"
                      : "Required Documents"}
                  </h2>
                </Col>
              </Col>
              {/* content */}
              <Col
                className="col-content-documentDetail"
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                xl={{ span: 20 }}
              >
                <Row>
                  {/* detail */}
                  <Col
                    className="col-content-documentDetail-detail"
                    xs={{ span: 22, offset: 1 }}
                    sm={{ span: 22, offset: 1 }}
                    xl={{ span: 22, offset: 1 }}
                  >
                    {/* document and office */}
                    <Row>
                      <Col
                        xs={{ span: 20 }}
                        sm={{ span: 12 }}
                        xl={{ span: 12 }}
                        className="col-content-documentDetail-detail-name"
                      >
                        <p className="p-topic-doucumentDrtail">
                          {language == 1
                            ? "เอกสารที่ใช้"
                            : "Required Documents"}{" "}
                          :{tyg_name}
                        </p>
                      </Col>
                      <Col
                        xs={{ span: 20 }}
                        sm={{ span: 12 }}
                        xl={{ span: 12 }}
                        className="col-content-documentDetail-detail-passport"
                      >
                        <p className="p-topic-doucumentDrtail">{sit_name}</p>
                      </Col>
                    </Row>
                    {/* tyw*/}
                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        xl={{ span: 24 }}
                        className="col-content-documentDetail-detail-date"
                      >
                        <p className="p-topic-doucumentDrtail">
                          {language == 1 ? "ประเภทงาน" : "Type"} :{tyw_name}
                        </p>
                      </Col>
                    </Row>
                    {/* tyw */}
                    <Row>
                      <Col
                        xs={{ span: 20 }}
                        sm={{ span: 22 }}
                        xl={{ span: 22 }}
                        className="col-content-documentDetail-detail-comment"
                      >
                        {/* <Row> */}
                        <p className="p-topic-doucumentDrtail">{tyw_comment}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DocumentDetail;
